import { Component } from "react";
import { DateFormat, SplitCamelCaseString } from "../../commonFunctions";
import HeaderComponent from "../../common_components/header/Header";
import LazyImageLoadComponent from "../../common_components/lazyImageLoad/LazyImageLoad";
import APP_CONST from "../../constants/appConstants";
import HTTP_CONST from "../../constants/httpConstants";
import { showLoader } from "../../redux/actions/LoaderAction";
import { showSnackBar } from "../../redux/actions/SnackBarAction";
import { getOrderDetailsService, cancelOrderService } from "../../services/OrderService";
import './OrderDetails.css';

const RejectedAccepted = ["Accepted", "Rejected"];
const TrackStatus = {
  "delivery": [
    "Pending",
    RejectedAccepted.join("/"),
    // "InProgress",
    "OutToDeliver",
    "Completed"
  ],
  "pickup": [
    "Pending",
    RejectedAccepted.join("/"),
    // "InProgress",
    "ReadyToPick",
    "Completed"
  ]
}

class OrderDetailsComponent extends Component {

  state = {
    order_detail: {},
    track: JSON.parse(JSON.stringify(TrackStatus)),
    isConfirmCancelVisible: false,
    isRefreshing: false,
    footerShowWhen: [APP_CONST.orderPending],
    title: ""
  }

  async componentDidMount() {
    showLoader(true);

    this.setState({
      title: this.props.match.params.order_id
    })

    let _order_detail = await getOrderDetailsService(this.props.match.params.order_id);

    if (_order_detail) {
      this.resetTrack(_order_detail);
      this.setState({ order_detail: _order_detail, title: this.state.title   });
    }
    setTimeout(() => showLoader(false), 2000);
  }

  checkCompleted(orderStatus, i) {
    let { track } = this.state;
    let tracks = JSON.parse(JSON.stringify(track[this.state.order_detail.isDeliver ? 'delivery' : 'pickup']));
    if (RejectedAccepted.includes(orderStatus)) {
      let _index = tracks.indexOf(RejectedAccepted.join("/"));
      tracks[_index] = orderStatus;
    }
    let index = tracks.indexOf(orderStatus);
    return index > i;
  }

  showStatus(status = "", orderStatus = "") {
    if (orderStatus === "Pending") return status;
    if (status.includes(orderStatus)) return orderStatus;
    if (status === RejectedAccepted.join("/")) return RejectedAccepted[0];
    return status;
  }

  getClass(orderStatus = "", status = "", i) {
    if (orderStatus === "Completed") return "progress__item--completed";
    else if (status === orderStatus) return "progress__item--active";
    else if (this.checkCompleted(orderStatus, i)) return "progress__item--completed";
    else if (status === RejectedAccepted.join("/") && status !== orderStatus) {
      if (orderStatus === "Rejected") return "progress__item--rejected"
      else if (orderStatus === "Accepted") return "progress__item--active";
      else return ""
    }
    return "_class";
  }

  async cancelOrder() {
    try {
      let { order_detail } = this.state;
      const orderCancelled = await cancelOrderService(order_detail);
      if (orderCancelled && orderCancelled.cancelOrder) {
        order_detail.orderStatus = orderCancelled.cancelOrder.orderStatus;
        this.resetTrack(order_detail);
        this.setState({ isConfirmCancelVisible: false, order_detail });
        if (orderCancelled.cancelOrder.allowCancel)
          showSnackBar("Order cancelled");
        else
          showSnackBar(`Order is in "${SplitCamelCaseString(order_detail.orderStatus)}" stage. Hence cannot be cancelled!`);
      } else {
        showSnackBar("Order cannot be cancelled");
      }
    } catch (error) {
      showSnackBar(HTTP_CONST.MESSAGE.PLEASE_TRY_AGAIN_LATER);
    }
  }

  resetTrack({ orderStatus, isDeliver }) {
    let track = JSON.parse(JSON.stringify(TrackStatus));
    if (orderStatus === "Rejected") {
      track[isDeliver ? "delivery" : "pickup"].splice([track[isDeliver ? "delivery" : "pickup"].length - 2]);
    }
    this.setState({ track });
  }

  showFooter(status) {
    const { footerShowWhen } = this.state;
    return footerShowWhen.includes(status);
  }

  async refreshOrderStatus() {
    this.setRefreshState(true);

    let { order_detail } = this.state;

    let _order_detail = await getOrderDetailsService(this.props.match.params.order_id);

    if (_order_detail) {
      order_detail.orderStatus = _order_detail.orderStatus;
      this.resetTrack(_order_detail);
      this.setState({ order_detail });
    }
    this.setRefreshState(false);
  }

  changeStatus(str = "", order) {
    if (APP_CONST.orderRejected === str) return `Cancelled By ${order && order.serviceProviderDetails && order.serviceProviderDetails.serviceProviderName ?  order.serviceProviderDetails.serviceProviderName : "Supplier"}`;
    if (APP_CONST.orderCancelled === str) return `Cancelled By Me`;
    return str;
  }

  setRefreshState(isRefreshing = false) {
    this.setState({ isRefreshing })
  }

  render() {
    const { order_detail, track, isConfirmCancelVisible, isRefreshing, title } = this.state;
    return (
      <>

        {
          isConfirmCancelVisible &&
          <div className="overlay-popup" >
            <div className="card">
              <p className="font-bold align-center p-10">Do you want to cancel the order?</p>
              <div className="flex m-tb-20" style={{ justifyContent: "center" }}>
                <button className="button-border m-lr-10" onClick={() => this.setState({ isConfirmCancelVisible: false })}>No</button>
                <button className="button-filled m-lr-10" onClick={() => this.cancelOrder()}>Yes</button>
              </div>
            </div>
          </div>
        }
        <HeaderComponent title={APP_CONST.headers.order_details.replace("<ORDER_NUMBER>", title)}></HeaderComponent>
        <div className={"content" + (this.showFooter(order_detail.orderStatus) ? " m-b-50" : "")}>
          <div className="grid order-details">

            <div className="card">
              <h3 className="w-90 m-tb-20 m-lr-auto">Details</h3>
              <div className="main flex m-t-10">
                <div className="color-light">Vendor: </div>
                <div className="font-bold">{order_detail.serviceProviderDetails && order_detail.serviceProviderDetails.serviceProviderName}</div>
              </div>
              <div className="main flex m-t-10">
                <div className="color-light">Ordered On: </div>
                <div className="font-bold">{DateFormat(order_detail.orderDate, APP_CONST.dateFormat)}</div>
              </div>
              <div className="main flex m-t-10">
                <div className="color-light">Order At: </div>
                <div className="font-bold">{DateFormat(order_detail.orderDate, APP_CONST.timeFormat)}</div>
              </div>
              {
                order_detail.totalPrice ?
                  <>
                    <div className="main flex m-t-10">
                      <div className="color-light">Total Price: </div>
                      <div className="flex font-bold"><i className="inr-sign" />&nbsp;{order_detail.totalPrice}</div>
                    </div>
                  </> : ""
              }
              <div className="main flex m-t-10 m-b-10">
                <div className="color-light">Delivery Type: </div>
                <div className="font-bold">{order_detail.isDeliver ? 'Delivery' : 'Pickup'}</div>
              </div>
            </div>

            <div className="card">
              <h3 className="flex w-90 m-tb-20 m-lr-auto justify-content-space-between">{order_detail.orderStatus !== APP_CONST.orderCancelled ? "Track your order" : "Order Status"}</h3>
              {
                order_detail.orderStatus !== APP_CONST.orderCancelled ?
                  <ul className="progress">
                    {
                      track[order_detail.isDeliver ? 'delivery' : 'pickup'].map((t, i) => {
                        return (
                          <li className={"progress__item font-bold font-80 " + (this.getClass(order_detail.orderStatus, t, i))} key={i}>
                            <p className="progress__info">{SplitCamelCaseString(this.changeStatus(this.showStatus(t, order_detail.orderStatus), order_detail))}</p>
                          </li>
                        )
                      })
                    }
                  </ul> : <p className="m-10 p-l-10">Cancelled By Me</p>
              }
            </div>
          </div>
          <div className="card">
            <h3 className="w-90 m-tb-20 m-lr-auto">Items</h3>
            <div className="grid">
              {
                order_detail && order_detail.items && order_detail.items.length && order_detail.items.map((order, i) => {
                  return (
                    <div className="flex main" key={i}>
                      <LazyImageLoadComponent class="item-img" src={order.itemIconURL} targetWindow="false"></LazyImageLoadComponent>
                      <div className="w-100 m-lr-10">
                        <div className="font-bold"><b>{order.itemName}</b></div>
                        <div className="font-80 color-light m-t-10">{order.subTotalPrice ? <><i className="inr-sign" /> {order.subTotalPrice / order.quantity} / {order.unitOfMeasure} * {order.quantity}</> : <>{order.quantity} {order.unitOfMeasure}</>}</div>
                      </div>
                      <p className="flex font-bold">{order.subTotalPrice ? <><i className="inr-sign" />&nbsp;{order.subTotalPrice}</> : ""}</p>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>


        {!isRefreshing && order_detail.orderStatus !== APP_CONST.orderCancelled && order_detail.orderStatus !== APP_CONST.orderRejected && order_detail.orderStatus !== APP_CONST.orderCompleted &&
          <div className="fab-icon cursor-pointer fab-order-details color-white" onClick={() => this.refreshOrderStatus()}><span className="undo"><i className="gg-undo"></i></span></div>}
        {isRefreshing && <div className="fab-icon cursor-pointer fab-order-details color-white"><span className="spinner"><i className="gg-spinner"></i></span></div>}


        { this.showFooter(order_detail.orderStatus) &&
          <div className="footer flex main w-100 m-0 p-0 background-white justify-content-end">
            <button className="button-filled" style={{ marginRight: "15px" }} onClick={() => this.setState({ isConfirmCancelVisible: true })}>Cancel Order</button>
          </div>
        }
      </>
    );
  }
}
export default OrderDetailsComponent;