import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import ServiceCataloguesComponent from "./components/serviceCatalogues/ServiceCatalogues";
import MyOrdersComponent from "./components/myOrders/MyOrders";
import CartComponent from "./components/cart/Cart";
import MenuComponent from "./common_components/menu/Menu";
import ServiceProvidersComponent from "./components/serviceProviders/ServiceProviders";
import CatalogItemsComponent from "./components/catalogItems/CatalogItems";
import APP_CONST from "./constants/appConstants";
import OrderDetailsComponent from "./components/orderDetails/OrderDetails";
import RegisterComponent from "./common_components/register/Register";
import QrCodeReaderComponent from "./common_components/qrCodeReader/QrCodeReader";
import AddServiceProviderUsingBusinessIdComponent from "./components/addServiceProviderUsingBusinessId/AddServiceProviderUsingBusinessId";

const routes = [
  {
    path: APP_CONST.routes.add_service_provider,
    component: QrCodeReaderComponent
  },
  {
    path: APP_CONST.routes.add_service_provider_using_business_id,
    component: AddServiceProviderUsingBusinessIdComponent
  },
  {
    path: APP_CONST.routes.service_providers,
    component: ServiceProvidersComponent
  },
  {
    path: APP_CONST.routes.register,
    component: RegisterComponent
  },
  {
    path: APP_CONST.routes.catelog_items,
    component: CatalogItemsComponent
  },
  {
    path: APP_CONST.routes.cart,
    component: CartComponent
  }, {
    path: APP_CONST.routes.my_orders,
    component: MyOrdersComponent
  },
  {
    path: APP_CONST.routes.order_details,
    component: OrderDetailsComponent
  },
  {
    path: APP_CONST.routes.serviceCatalogues,
    component: ServiceCataloguesComponent
  }
];

function RouterComponent() {
  return (
    <Router>
      <MenuComponent></MenuComponent>
      <div>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
    </Router>
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
      s />
  );
}
export default RouterComponent;