import { GetDetailsFromStorage } from "../commonFunctions";
import API_CONST from "../constants/apiConstants";
import APP_CONST from "../constants/appConstants";
import HTTP_CONST from "../constants/httpConstants";
import { changeCartReseted, deleteCartAction } from "../redux/actions/CartAction";
import { GetApiCall, PostApiCall } from "./ApiCallRequestService";


function placeOrderService({ serviceProviderId, isDeliver }) {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   deleteCartAction(serviceProviderId);

      //   return resolve({
      //     id: 1
      //   });
      // }

      const body = {
        serviceProviderId,
        isDeliver,
        customerId: (await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId
      }

      let placeOrder = await PostApiCall(API_CONST.placeOrder, body)
      deleteCartAction(serviceProviderId);
      changeCartReseted(true);

      return resolve(placeOrder);

    } catch (error) {
      return reject(error);
    }
  });
}

function cancelOrderService({ orderId, categoryId }) {
  return new Promise(async (resolve, reject) => {
    try {

      const body = {
        orderId: String(orderId),
        categoryId
      }

      let updateOrderStatus = await PostApiCall(API_CONST.cancelOrder, body);

      return resolve(updateOrderStatus);

    } catch (error) {
      return reject(error);
    }
  });
}

function getMyOrdersService(params = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve(require("../fake_services/Orders.json"));
      // }
      params.customerId = (await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId

      const myOrders = await GetApiCall(API_CONST.getOrders, params);

      if (Array.isArray(myOrders)) {
        return resolve(myOrders);
      } else {
        return reject({message: HTTP_CONST.MESSAGE.NO_ORDERS})
      }

    } catch (error) {
      return reject(error);
    }
  });
}

function getOrderDetailsService(orderId) {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve(require("../fake_services/OrderDetails.json")[orderId]);
      // }
      let orderDetails = await GetApiCall(API_CONST.getOrderDetails, { orderId });

      return resolve(orderDetails);

    } catch (error) {
      return reject(error);
    }
  });
}



export {
  placeOrderService,
  getMyOrdersService,
  getOrderDetailsService,
  cancelOrderService
};