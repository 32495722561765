import HeaderComponent from "../header/Header";
import { Component } from "react";
import './Register.css';
import APP_CONST from "../../constants/appConstants";
import { REDUX_REDUCERS_VARIABLES } from "../../constants/reduxConstants";
import store from "../../redux/Store";
import { CheckCustomerExists, ConfirmOtp, Register, ReSendOtp, SendOtp } from "../../services/AuthService";
import { withRouter } from "react-router-dom";
import { IsValidName, StoreDetailsToStorage } from "../../commonFunctions";
import { isUserLoggedInAction } from "../../redux/actions/LoginAction";
import { GetPosition } from "../../services/LocationService";
import { getCustomerInfo } from "../../services/UserService";
// import QrCodeReaderComponent from "../qrCodeReader/QrCodeReader";
import ShowNotSubscribed from '../showNotSubscribed/ShowNotSubscribed';


class RegisterComponent extends Component {

	state = {
		first_name: "",
		last_name: "",
		code: "91",
		number: "",
		address: "",
		pinCode: "",
		flag: false,
		error: {
			first_name: false,
			last_name: false,
			number: false,
			address: false,
			pinCode: false
		},
		location: {
			lat: "",
			lng: ""
		},
		showOtp: false,
		otp_first: "",
		otp_second: "",
		otp_third: "",
		otp_fourth: "",
		otpValidFor: 0,
		showProgressRegister: false,
		showProgressOtp: false,
		showProgressResndOtp: false,
		otpConfirmError: "",
		showNotSubscribed: false,
		messageNotSubscribed: "",
		videoUrl: "",
		redirectToUrl: "",
		registered: {
			message: {}
		}
	}

	interval;

	componentDidMount() {
		let _state = store.getState();

		if (_state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN]) { this.goBack(); }
		else console.log("not Logged in");
	}

	autoDetectOtp() {
		if ("OTPCredential" in window) {
			const ac = new AbortController();
			setTimeout(() => {
				ac.abort();
			}, 1 * this.state.otpValidFor * 1000);


			navigator.credentials
				.get({
					otp: { transport: ["sms"] },
					signal: ac.signal
				})
				.then((otp) => {
					console.log(otp);
					if (otp && otp.length === 4) {
						this.setState({
							otp_first: otp.code[0],
							otp_second: otp.code[1],
							otp_third: otp.code[2],
							otp_fourth: otp.code[3],
						});
					}
					ac.abort();
				})
				.catch((err) => {
					ac.abort();
					console.log(err);
				});
		}
	}

	async checkCustomerExists() {

		if (!this.checkFormValid()) {
			return;
		}
		this.setState({ otpValidFor: 0, showProgressRegister: true, showProgressOtp: false, otpConfirmError: "", errorMsg: "" });

		try {
			const CustomerExists = await CheckCustomerExists(this.state.number, this.state.code);
			this.setState({ showProgressRegister: false });
			if (CustomerExists && CustomerExists.customerExists) {
				this.sendOtp();
			} else {
				this.setState({ errorMsg: "Mobile number already registered" });
			}
		} catch (error) {
			this.setState({ showProgressRegister: false })
		}



	}

	async sendOtp(resend = false) {
		this.setState({ otpValidFor: 0, showProgressRegister: true, showProgressOtp: false, otpConfirmError: "", errorMsg: "" });

		if (resend) {
			this.setState({ showProgressResndOtp: true });
		}

		try {
			let { lat, lng } = await GetPosition();
			console.log({ lat, lng });

			this.setState({ location: { lat, lng } });



		} catch (error) {

		}

		try {
			const { code, number } = this.state;
			const sendOtp = !resend ? await SendOtp({ code, number }) : await ReSendOtp({ code, number });
			if (sendOtp && sendOtp.OTPSent) {
				this.setState({ showOtp: true, otpValidFor: 120 });
				this.autoDetectOtp();
				this.interval = setInterval(() => {
					this.setState({ otpValidFor: this.state.otpValidFor - 1 });
					if (!this.state.otpValidFor) { this.setState({ showProgressResndOtp: false }); clearInterval(this.interval); }
				}, 1000)
			}
		} catch (error) {
			this.setState({ showProgressRegister: false })
		}
	}

	checkFormValid() {
		let error = {
			first_name: false,
			last_name: false,
			number: false,
			pinCode: false,
			address: false
		}
		this.setState({ error, errorMsg: "" });

		if (!IsValidName(this.state.first_name)) error.first_name = true;
		if (!IsValidName(this.state.last_name, 1)) error.last_name = true;
		if (!this.validateNumber(this.state.number)) error.number = true;
		if (!this.state.address || this.state.address.length < 6) error.address = true;
		if (!this.state.pinCode || this.state.pinCode.length !== 6) error.pinCode = true;

		this.setState({ error })

		return !(error.first_name || error.last_name || error.number || error.address || error.pinCode);
	}

	async register() {
		if (!this.checkFormValid()) {
			return;
		}

		let errorMsg = "Registration can not be completed right now. Please try again later";
		try {
			// this.setState({error:{}})

			let url = this.props.location.search.replace("?url=", "");

			const registered = await Register({
				customerName: this.state.first_name,
				lastName: this.state.last_name,
				mobileNumber: `${this.state.code}${this.removeSpace(this.state.number)}`,
				pinCode: this.state.pinCode, address: this.state.address,
				source: url.includes("catelog_items") ? 3 : 2,
				serviceProviderId: url.includes("catelog_items") ? url.split("/")[2] : "",
			});
			console.log({ registered });
			if (registered && registered.customerId) {
				this.setState({
					registered
				})
				await this.sendOtp();
			} else {
				this.setState({ errorMsg, showProgressOtp: false, showProgressRegister: false });
			}

		} catch (error) {
			console.log({ asd: JSON.parse(JSON.stringify(error)) });

			if (error && error.code === 400 && Object.hasOwnProperty.call(error, "first_name")) {
				delete error.code;
				delete error.message;
				this.setState({ error: { ...this.state.error, ...error }, showProgressOtp: false, showProgressRegister: false }, console.log);
				console.log(this.state)
				return;
			} else if (error && error.message && error.message.includes("Customer_mobileNumber_key")) {
				errorMsg = "Mobile number already registered";
			}
			else { errorMsg = (error && error.message) || errorMsg; }
			console.log({ errorMsg });

			this.setState({ errorMsg, showProgressOtp: false, showProgressRegister: false });
		}
	}

	async confirmOtp() {
		try {

			this.setState({ showProgressOtp: true, otpConfirmError: "", errorMsg: "" });
			const { otp_first, otp_second, otp_third, otp_fourth, code, number } = this.state;
			if (!otp_first || !otp_second || !otp_third || !otp_fourth) {
				this.setState({ otpConfirmError: "Invalid OTP", showProgressOtp: false })
				return;
			}
			const verified = await ConfirmOtp({ code, number: this.removeSpace(number), otp: String(otp_first) + String(otp_second) + String(otp_third) + String(otp_fourth) });
			if (verified && verified.OTPVerified) {
				this.setState({ showOtp: false });
				try {

					const customerInfo = await getCustomerInfo(this.state.code + this.removeSpace(this.state.number));
					if (customerInfo && customerInfo.customerId) {
						await StoreDetailsToStorage(APP_CONST.user_details_key, customerInfo);
						isUserLoggedInAction();
						this.decideWhichWelcomeMessage(this.state.registered.message);
					}

				} catch (error) {
					console.log({ error });
					this.setState({ errorMsg: "Can not fetch the customer detail", showProgressOtp: false, showProgressRegister: false });
				}
			} else {
				this.setState({ otpConfirmError: "Invalid OTP", showProgressOtp: false, showProgressRegister: false });

			}
		} catch (error) {
			this.setState({ otpConfirmError: error.message, showProgressOtp: false, showProgressRegister: false });
		}

	}

	goBack() {
		const redirectTo = this.state.redirectToUrl ? this.state.redirectToUrl : this.props.location.search.replace("?url=", "");
		if (redirectTo) {
			this.props.history.push(redirectTo);
		} else {
			this.props.history.goBack();
		}
	}

	decideWhichWelcomeMessage({ message, redirectToUrl, videoUrl }) {

		this.setState({
			showNotSubscribed: true,
			messageNotSubscribed: decodeURIComponent(message),
			videoUrl,
			redirectToUrl: redirectToUrl ? redirectToUrl : this.props.location.search.replace("?url=", "")
		});
	}

	numberUpdated = (e) => {
		let value = this.removeSpace(e.target.value);

		if (isNaN(value) || value.length > 10) return;

		value = value.replace(/([0-9]{4})([0-9]{3})?([0-9]{3})?/i, "$1 $2 $3");
		this.setState({ number: String(value).trim() });
		value = this.removeSpace(value);
		this.setState({ flag: !this.validateNumber(value) && value.length === 10 });
	};

	removeSpace(str = "") {
		return String(str).replace(/\s+/g, "")
	}

	validateNumber(number = "") {
		const regex = /^[6789]\d{9}$/;
		number = this.removeSpace(number);
		return regex.test(number) && number.length === 10;
	}

	switchFocus(element) {
		if (element.key === "Delete" || element.key === "Backspace") {
			if (element.target.previousSibling)
				element.target.previousSibling.focus();
		} else {
			if (element.target.nextSibling)
				element.target.nextSibling.focus();
		}
	}

	render() {

		const { first_name, last_name, number, pinCode, code, address, error, showOtp, otp_first, otp_fourth, otp_second, otp_third, otpValidFor, showProgressRegister, showProgressOtp, showProgressResndOtp, otpConfirmError, showNotSubscribed, messageNotSubscribed, videoUrl, errorMsg } = this.state;

		return (
			<>

				<HeaderComponent title={APP_CONST.headers.register} hideLogin={true}></HeaderComponent>
				{/* <QrCodeReaderComponent clickFn={() => this.goBack()}/>
				<div>X</div> */}

				<div className="content">
					<div className="card">

						<input
							className="register-input m-0"
							id="first_name"
							name="first_name"
							type="text"
							value={first_name}
							placeholder="First Name"
							onChange={(e) => this.setState({ first_name: e.target.value })} />
						{error.first_name && <p className="font-80 color-red">Please enter valid first name</p>}

						<input
							className="register-input"
							id="last_name"
							name="last_name"
							type="text"
							value={last_name}
							placeholder="Last Name"
							onChange={(e) => this.setState({ last_name: e.target.value })} />
						{error.last_name && <p className="font-80 color-red">Please enter valid last name</p>}

						<div>
							<span className="p-r-10 code font-80">+{code}</span>

							<input
								className="register-input mobile"
								id="mobile"
								name="mobile"
								type="text"
								value={number}
								placeholder="9999 999 999"
								onChange={this.numberUpdated} />
						</div>
						{error.number && <p className="font-80 color-red">Please enter valid mobile</p>}
						{errorMsg && <p className="font-80 color-red">{errorMsg}</p>}

						<textarea
							id="address"
							name="address"
							value={address}
							placeholder="Address"
							rows="5"
							onChange={(e) => this.setState({ address: e.target.value })}>

						</textarea>
						{error.address && <p className="font-80 color-red">Please enter valid address</p>}
						<input
							className="register-input m-b-10"
							id="pincode"
							name="pincode"
							type="number"
							value={pinCode}
							placeholder="Pin code"
							onChange={(e) => this.setState({ pinCode: e.target.value })} />
						{error.pinCode && <p className="font-80 color-red">Please enter valid pin code</p>}

					</div>


					<div className="footer flex main w-100 m-0 p-0 background-white">
						<span></span>
						<button className="button-filled" style={{ marginRight: "15px" }} disabled={showProgressRegister} onClick={() => this.register()}>Register{showProgressRegister && <i className="gg-spinner"></i>}</button>
					</div>

					{
						showOtp &&
						<div className="overlay-popup">
							<div className="card align-center">
								<p className="font-bold m-b-0">Please enter the OTP sent to </p>
								<p className="font-bold m-t-5 m-b-0">+{code}-{number}</p>
								<div className="m-t-0 m-b-10 flex justify-content-center">
									<input
										className="otp"
										id="otp1"
										tabIndex="1"
										name="otp1"
										type="number"
										value={otp_first}
										maxLength="1"
										onKeyUp={e => this.switchFocus(e)}
										onChange={e => this.setState({ otp_first: e.target.value })} />

									<input
										className="otp"
										id="otp2"
										tabIndex="2"
										name="otp2"
										type="number"
										value={otp_second}
										maxLength="1"
										onKeyUp={e => this.switchFocus(e)}
										onChange={e => this.setState({ otp_second: e.target.value })} />

									<input
										className="otp"
										id="otp3"
										tabIndex="3"
										name="otp3"
										type="number"
										value={otp_third}
										maxLength="1"
										onKeyUp={e => this.switchFocus(e)}
										onChange={e => this.setState({ otp_third: e.target.value })} />

									<input
										className="otp"
										id="otp4"
										tabIndex="4"
										name="otp4"
										type="number"
										value={otp_fourth}
										maxLength="1"
										onKeyUp={e => this.switchFocus(e)}
										onChange={e => this.setState({ otp_fourth: e.target.value })} />
								</div>
								<div className="color-light m-b-20 font-80">
									{
										otpConfirmError && <p className="m-0 color-red">{otpConfirmError}</p>
									}
									{
										otpValidFor ?
											<span>OTP is valid for {otpValidFor} seconds</span> :
											<span>Please click on "RESEND"<br /> If you haven't received OTP</span>
									}
								</div>

								<div className="flex justify-content-center">
									<button className="button-border m-lr-10" onClick={() => this.setState({ showOtp: false, showProgressRegister: false, showProgressOtp: false, showProgressResndOtp: false })}>Cancel</button>
									{
										otpValidFor ?
											<button className="button-filled m-lr-10" disabled={showProgressOtp} onClick={() => this.confirmOtp()}>Confirm{showProgressOtp && <i className="gg-spinner"></i>}</button>
											:
											<button className="button-filled m-lr-10" disabled={showProgressResndOtp} onClick={() => this.sendOtp(true)}>Resend{showProgressResndOtp && <i className="gg-spinner"></i>}</button>
									}
								</div>
							</div>
						</div>
					}
				</div>



				{
					showNotSubscribed &&
					<ShowNotSubscribed showNotSubscribed={showNotSubscribed} message={messageNotSubscribed} videoUrl={videoUrl} close={() => this.goBack()}></ShowNotSubscribed>
				}
			</>
		)
	};
}

export default withRouter(RegisterComponent);