import "./Card.css";
import LazyImageLoadComponent from "../lazyImageLoad/LazyImageLoad";

function CardServiceProviderComponent(params) {
  function handleClick(event) {
    return params.clickFn ? params.clickFn() : false;
  }

  return (
    <div className={"card" + (params.containerClass ?" "+ params.containerClass : "")} onClick={handleClick}>
      <div className={params.class ? params.class : ""}>
        {params.img && <LazyImageLoadComponent allowfullscreen='true' class={params.imgClass} src={params.img} targetWindow="false"></LazyImageLoadComponent>}

        <div className="card-title-left font-bold w-100 m-tb-auto" style={{ marginLeft: '15px' }}>
          <div >{params.title}</div>
          <div><span className="address-fontsize">{params.address}</span></div>
          <div><span className="address-fontsize">{params.pincode}</span></div>
        </div>
      </div>
    </div>
  );
}

export default CardServiceProviderComponent;
