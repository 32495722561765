import APP_CONST from "../constants/appConstants";
import {
  REDUX_ACTION,
  REDUX_REDUCERS_INITIAL_STATE
} from "../constants/reduxConstants";

const reducer = (state = REDUX_REDUCERS_INITIAL_STATE, action) => {
  switch (action.type) {

    case REDUX_ACTION.RESET_STATE:
      state = REDUX_REDUCERS_INITIAL_STATE;
      return state;

    case REDUX_ACTION.UPDATE_CART_COUNT:
      // console.trace(JSON.parse(JSON.stringify({ cartCount: action.payload.count })));
      state.CART_COUNT = action.payload.count;
      return state;

    case REDUX_ACTION.IS_USER_LOGGED_IN:
      const user = localStorage.getItem(APP_CONST.user_details_key) ? JSON.parse(localStorage.getItem(APP_CONST.user_details_key)) : {};
      state.IS_USER_LOGGED_IN = !!user.customerId;
      return state;

    case REDUX_ACTION.SHOW_SNACKBAR:
      state.SHOW_SNACKBAR = action.payload.message;
      return state;

    case REDUX_ACTION.SHOW_LOADER:
      state.SHOW_LOADER = action.payload.SHOW_LOADER;
      return state;

    case REDUX_ACTION.SHOW_MESSAGE:
      state.SHOW_MESSAGE = action.payload.showMessage;
      return state;

    case REDUX_ACTION.TOGGLE_MENU_OPEN:
      state.IS_MENU_OPEN = !state.IS_MENU_OPEN;
      return state;

    case REDUX_ACTION.TOGGLE_USER_CONFIRMATION_ON_CART:
      state.IS_USER_CONFIRMED_TO_CONTINUE_CART = action.payload.confirm;
      return state;

    case REDUX_ACTION.DELETE_CART:
      state.CART_COUNT = 0;
      return state;

    case REDUX_ACTION.CHANGE_CART_RESETED:
      state.CART_RESETED = action.payload.CART_RESETED;
      return state;

    default:
      return state;
  }
}

export default reducer;
