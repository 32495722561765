import HeaderComponent from "../../common_components/header/Header";
import { Component } from "react";
import CardComponent from "../../common_components/card/Card";
import './ServiceCatalogues.css';
import { getAllServiceCataloguesService } from "../../services/ServiceCataloguesService";
import APP_CONST from "../../constants/appConstants";
import { showLoader } from "../../redux/actions/LoaderAction";
import { isUserLoggedInAction } from "../../redux/actions/LoginAction";
import store from "../../redux/Store";
import { REDUX_REDUCERS_VARIABLES } from "../../constants/reduxConstants";

import { showSnackBar } from '../../redux/actions/SnackBarAction';
import HTTP_CONST from "../../constants/httpConstants";



class ServiceCataloguesComponent extends Component {

	state = {
		serviceCatalogues: [],
		showLogin: false
	}


	async componentDidMount() {
		this.getAllServiceCatalogues();
	}

	async getAllServiceCatalogues() {
		showLoader(true);
		try {
			let _serviceCatalogues = await getAllServiceCataloguesService();
			this.setState({ serviceCatalogues: _serviceCatalogues });
		} catch (error) {
			this.props.history.push(`${this.props.match.url}?url=/`)
			showSnackBar(HTTP_CONST.MESSAGE.PLEASE_TRY_AGAIN_LATER);
		}

		showLoader(false);

	}

	categorySelected(category) {

		isUserLoggedInAction();
		const _state = store.getState();
		this.setState({ showLogin: !_state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN] });
		this.props.history.push(`${this.props.match.url}?url=${encodeURI(APP_CONST.routes.service_providers.replace(":category_id", category.categoryId))}`)

		if (_state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN]) {
			this.props.history.push(APP_CONST.routes.service_providers.replace(":category_id", category.categoryId));
		}
	}

	render() {
		const { serviceCatalogues, showLogin } = this.state;

		return (
			<>
				<HeaderComponent title={APP_CONST.headers.serviceCatalogues} showLogin={showLogin}></HeaderComponent>
				<div className="content">
					<div className="wrapper-service-catalogue">
						{
							serviceCatalogues.map((category, i) => {
								return (
									<CardComponent
										img={category.categoryIconURL}
										imgClass="m-lr-auto w-50 block m-b-20"
										containerClass="m-0"
										clickFn={() => this.categorySelected(category)}
										key={category.categoryId}
										title={category.categoryName} />

								);
							})
						}
					</div>
				</div>
			</>
		)
	};
}

export default ServiceCataloguesComponent;