import './Header.css';
import React, { Component } from 'react';
import store from '../../redux/Store';
import { withRouter } from 'react-router-dom';
import APP_CONST from '../../constants/appConstants';
import { toggleMenuOpen } from '../../redux/actions/MenuAction';
import { REDUX_REDUCERS_VARIABLES } from '../../constants/reduxConstants';
import { isUserLoggedInAction } from '../../redux/actions/LoginAction';
import { getCustomerInfo, logout } from '../../services/UserService';
import { GetDetailsFromStorage, LimitString, StoreDetailsToStorage } from '../../commonFunctions';
import LoginComponent from '../login/Login';
// import { showSnackBar } from '../../redux/actions/SnackBarAction';
import ShowNotSubscribed from '../showNotSubscribed/ShowNotSubscribed';
import { ConfirmOtp, SendOtp } from '../../services/AuthService';

const showMenusIn = [
  APP_CONST.routes.serviceCatalogues,
  APP_CONST.routes.my_orders
]

class HeaderComponent extends Component {

  constructor(props) {
    super(props);
    this.outsideClicked = this.outsideClicked.bind(this);
  }

  subscribe = null;

  state = {
    cartCount: 0,
    showBasket: false,
    showBack: false,
    isLoggedIn: false,
    showMenu: false,
    showLogin: false,
    showNotSubscribed: false,
    customerName: "",
    hideLogin: false,
    showVideo: false,
    redirectToUrl: "",
    messageNotSubscribed: "",
    showSpinner: false,
    code: "",
    showConfirmOtp: false,
    otpConfirmError: "",
    otpValidFor: 120
  }
  interval;
  menuContainer = React.createRef();

  async componentDidMount() {
    window.addEventListener("click", this.outsideClicked, false);

    let _state = store.getState();
    isUserLoggedInAction();
    let customer = "";

    if (_state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN]) {
      customer = await GetDetailsFromStorage(APP_CONST.user_details_key);
      customer = customer.customerName || "";
    }

    this.setState({ showBack: !showMenusIn.includes(this.props.match.path), isLoggedIn: _state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN], customerName: customer });

    if (this.props.match.path === APP_CONST.routes.catelog_items) {
      this.setState({ showBasket: true, cartCount: _state[REDUX_REDUCERS_VARIABLES.CART_COUNT] });
      this.subscribe = store.subscribe(() => {
        _state = store.getState();
        this.setState({ cartCount: _state[REDUX_REDUCERS_VARIABLES.CART_COUNT] })
      });
    }
    this.setState({ hideLogin: !!this.props.hideLogin });

  }

  goBack() {
    this.props.history.goBack();
  }

  cartIconClicked() {
    if (this.state.cartCount) {
      this.props.history.push(APP_CONST.routes.cart.replace(":service_provider_id", this.props.match.params.service_provider_id));
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.showLogin) {
      return { showMenu: true, showLogin: true }
    }
    return null
  }

  userIconClicked(e, showMenu) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ showMenu: !showMenu });
    }
  }

  async sendOtp() {

    try {
      this.setState({ code: "", otpConfirmError: "", otpValidFor: 0 })
      const sendOtp = await SendOtp({ code: this.state.showConfirmOtp.slice(0, 2), number: this.state.showConfirmOtp.slice(2) })
      if (sendOtp && sendOtp.OTPSent) {
        this.setState({ otpValidFor: 120 });
        this.interval = setInterval(() => {
          this.setState({ otpValidFor: this.state.otpValidFor - 1 });
          if (!this.state.otpValidFor) { this.setState({ showLoader: false }); clearInterval(this.interval); }
        }, 1000)
      }
    } catch (error) {
      this.setState({ showProgressRegister: false })
    }
  }

  async onLogin(event) {
    console.log(event);
    if (event && event.loggedIn) {
      let customer = await GetDetailsFromStorage(APP_CONST.user_details_key);
      this.setState({
        showMenu: false,
        isLoggedIn: true,
        customerName: customer.customerName || "",
        showLogin: false,
        redirectToUrl: event.redirectToUrl
      }, () => {
        if (this.props.onChanged)
          this.props.onChanged({ loggedIn: true });
      });
      // showSnackBar(event.message);
    } else {
      if (event.showConfirmOtp) {
        this.setState({
          showConfirmOtp: event.showConfirmOtp,
        });
        this.sendOtp();
      } else {
        this.setState({
          messageNotSubscribed: event.messageNotSubscribed,
          redirectToUrl: event.redirectToUrl,
          showVideo: event.videoUrl,
          showNotSubscribed: true,
          isLoggedIn: false,
          showLogin: false,
          showMenu: false,
        });
      }
    }
  }
  numberUpdated = (e) => {
    let value = this.removeSpace(e.target.value);

    if (isNaN(value)) return;

    this.setState({ code: value });
  };

  removeSpace(str = "") {
    return String(str).replace(/\s+/g, "")
  }

  async confirmOtp() {
    try {

      this.setState({ showLoader: true, otpConfirmError: "", errorMsg: "" });
      if (!this.state.code || !this.state.code.length || this.state.code.length !== 4) {
        this.setState({ otpConfirmError: "Invalid OTP", showLoader: false })
        return;
      }
      let code = this.state.showConfirmOtp.slice(0, 2), number = this.state.showConfirmOtp.slice(2);

      const verified = await ConfirmOtp({ code, number: this.removeSpace(number), otp: String(this.state.code) });
      if (verified && verified.OTPVerified) {
        try {

          const customerInfo = await getCustomerInfo(code + this.removeSpace(number));
          if (customerInfo && customerInfo.customerId) {
            await StoreDetailsToStorage(APP_CONST.user_details_key, customerInfo);
            isUserLoggedInAction();
            let customer = await GetDetailsFromStorage(APP_CONST.user_details_key);
            this.setState({
              isLoggedIn: true,
              showMenu: false,
              customerName: customer.customerName || "",
              showLogin: false,
              showConfirmOtp: false
            }, () => {
              if (this.props.onChanged)
                this.props.onChanged({ loggedIn: true });
            });
          }

        } catch (error) {
          console.log({ error });
          this.setState({ errorMsg: "Can not fetch the customer detail", showLoader: false });
        }
      } else {
        this.setState({ otpConfirmError: "Invalid OTP", showLoader: false });

      }
    } catch (error) {
      this.setState({ otpConfirmError: error.message, showLoader: false });
    }

  }

  openMenu() {
    toggleMenuOpen();
  }

  outsideClicked(e) {
    if (this.menuContainer && this.menuContainer.current && !e.target.parentNode.className.includes("isMenu") && this.state.showMenu && !this.state.showLogin) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ showMenu: false });
    }
  }

  goToHome() {
    this.props.history.push(APP_CONST.routes.serviceCatalogues)
  }

  async logout() {
    await logout();
    this.setState({ isLoggedIn: false, showMenu: false, customerName: "" });
    isUserLoggedInAction();
    this.props.history.push(APP_CONST.routes.serviceCatalogues);
  }

  onMessageDialogClosed(e) {
    this.setState({
      showNotSubscribed: false,
      showConfirmOtp: e && e.showConfirmOtp
    });
    if (this.state.redirectToUrl && !e)
      this.props.history.push(this.state.redirectToUrl);
  }

  render() {

    const { showBack, showBasket, hideLogin, isLoggedIn, cartCount, showMenu, customerName, showNotSubscribed, messageNotSubscribed, showVideo, showConfirmOtp, showSpinner, code, otpValidFor, otpConfirmError } = this.state;

    return (
      <div>
        <header className="background-primary color-secondary">
          <div className="left flex">

            {(showBack || this.props.showBack) && <span onClick={() => this.goBack()}><i className="gg-arrow-left cursor-pointer"></i> </span>}
            {!(showBack || this.props.showBack) && <span onClick={() => this.openMenu()}><i className="gg-menu-left-alt cursor-pointer"></i></span>}
            {(showBack || this.props.showBack) && <span onClick={() => this.goToHome()} style={{ marginLeft: "10px", marginTop: "5px" }}><i className="gg-home cursor-pointer"></i></span>}
          </div>

          <div className="center">{this.props.title ? this.props.title : ""}</div>
          {
            !hideLogin &&
            <div className="right flex ismenu">
              {
                showBasket &&
                <i className="gg-shopping-cart cursor-pointer" onClick={() => this.cartIconClicked()}>
                  {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
                </i>
              }
              <span className="flex flex-coloumn" style={{ marginLeft: "10px" }} onClick={(e) => this.userIconClicked(e, showMenu)}>
                <i className="gg-user cursor-pointer">
                </i>
                <span className="font-80 m-t-5">{LimitString(customerName) || "Sign in"}</span>
              </span>
            </div>
          }
        </header>
        {
          showMenu &&
          <div className="menu" ref={this.menuContainer}>
            {
              isLoggedIn && !hideLogin ? <>
                <div className="p-10 cursor-pointer" onClick={() => this.logout()}>Logout</div>
              </> : <LoginComponent onLogin={(e) => this.onLogin(e)}></LoginComponent>
            }
          </div>
        }
        {
          showConfirmOtp &&
          <div className="overlay-popup">
            <div className="card not-subcribed">
              <i className="gg-close" style={{ marginLeft: "auto" }} onClick={() => this.setState({ showConfirmOtp: false })}></i>

              <div className="m-t-20 isMenu">
                <input
                  id="mobile"
                  name="mobile"
                  type="text"
                  className="login"
                  value={code}
                  placeholder="OTP"
                  onChange={this.numberUpdated}
                  onKeyPress={(e) => { e.key === 'Enter' && this.confirmOtp() }} />
              </div>

              <div className="color-light m-b-10 font-80 m-t-10 align-center">
                {
                  otpConfirmError && <p className="m-0 color-red">{otpConfirmError}</p>
                }
                {
                  otpValidFor && <span>OTP is valid for {otpValidFor} seconds</span>
                }
              </div>
              <div className="flex" style={{ justifyContent: "center" }}>
                <button className="button-filled m-10 m-b-0" onClick={() => this.confirmOtp()}>Confirm OTP {showSpinner && <i className="gg-spinner"></i>}</button>
              </div>
            </div>
          </div>
        }

        {
          showNotSubscribed &&
          <ShowNotSubscribed showNotSubscribed={showNotSubscribed} message={messageNotSubscribed} videoUrl={showVideo} close={(e) => this.onMessageDialogClosed({ showConfirmOtp })}></ShowNotSubscribed>
        }
      </div>
    );
  }

  componentWillUnmount() {

    if (this.props.match.path === APP_CONST.routes.catelog_items) {
      this.setState({ showBasket: false })
    }
    if (this.subscribe)
      this.subscribe();
    window.removeEventListener("click", this.outsideClicked, false);
  }
}

export default withRouter(HeaderComponent);

