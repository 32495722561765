import './App.css';
import '../../styles/icons.css';
import RouterComponent from "../../Router";
import LoaderComponent from '../../common_components/loader/Loader';
import SnackBarComponent from '../../common_components/snackBar/SnackBar';

function App() {
  return (
    <>
      <RouterComponent></RouterComponent>
      <LoaderComponent></LoaderComponent>
      <SnackBarComponent></SnackBarComponent>
    </>
  );
}

export default App;