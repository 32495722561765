import Logo from '../../logo.svg';

import React, { Component } from "react";
import "./LazyImageLoad.css"

class LazyImageLoadComponent extends Component {

    constructor(props) {
        super(props);
        this.srolled = this.srolled.bind(this);
    }
    state = {
        imagesrc: Logo,
        loaded: false,
        imageOffestTop: 0,
        fullscreen: false
    }

    imgRef = React.createRef();

    async componentDidMount() {
        this.setState({ imageOffestTop: this.imgRef.current.getBoundingClientRect()['top'] });
        if ((window.pageYOffset + window.innerHeight) >= this.state.imageOffestTop) {
            this.loadImg();
        } else {
            window.addEventListener('scroll', this.srolled, true)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.imagesrc !== props.src && !String(props.src).endsWith("/null")) {
            return { imagesrc: props.src };
        }
        return {};
    }

    srolled(event) {
        let winowoffsetHeight = window.pageYOffset + window.innerHeight;
        if (this.params.targetWindow === "false")
            winowoffsetHeight += event.srcElement.scrollTop;
        if (winowoffsetHeight >= this.state.imageOffestTop && !this.state.loaded) {
            this.loadImg();
        }
    }

    loadImg() {
        let img = new Image();
        img.onload = () => {
            this.setState({
                imagesrc: this.state.imagesrc,
                loaded: true
            });
        }
        img.onerror = (err) => {
            console.log(err,"error while loading");
            this.setState({
                imagesrc: Logo,
                loaded: true
            });
        }
        img.src = this.state.imagesrc;
    }

    toggleFullScreen(e) {
        if (!this.props.allowfullscreen) return;
        if (String(this.state.imagesrc).includes("generalItem.png") || String(this.state.imagesrc).includes("local-store.png")) return;
        e.stopPropagation(); e.preventDefault();
        this.setState({
            fullscreen: !this.state.fullscreen
        })
    }

    render() {
        let { loaded, imagesrc, fullscreen } = this.state;
        return (
            <>
                <div className={(!loaded ? "loaded" : "") + "" + (this.props.class ? " " + this.props.class : "")}>
                    <img src={imagesrc} className={loaded ? "loaded" : ""} ref={this.imgRef} width="100" alt="Local Shopwala" onClick={(e) => { this.toggleFullScreen(e); }} />
                </div>

                {
                    fullscreen &&
                    <div className="overlay-popup">
                        <i className="gg-close color-primary" style={{ marginLeft: "auto" }} onClick={(e) => this.toggleFullScreen(e)}></i>
                        <img src={imagesrc} className="w-90 m-t-10" alt="Local Shopwala" />
                    </div>
                }
            </>
        )
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.srolled, true);
    }
}

export default LazyImageLoadComponent;