import './Login.css';
import React, { Component } from "react";

import APP_CONST from '../../constants/appConstants';
import { StoreDetailsToStorage } from '../../commonFunctions';
import { getCustomerInfo } from '../../services/UserService';
import { Link, withRouter } from 'react-router-dom';
import { isUserLoggedInAction } from '../../redux/actions/LoginAction';
import { SendOtp } from '../../services/AuthService';

class LoginComponent extends Component {

  state = {
    number: "",
    code: "91",
    flag: false,
    showSpinner: false
  };

  interval = null;

  numberUpdated = (e) => {
    let value = this.removeSpace(e.target.value);

    if (isNaN(value) || value.length > 10) return;

    value = value.replace(/([0-9]{4})([0-9]{3})?([0-9]{3})?/i, "$1 $2 $3");
    this.setState({ number: String(value).trim() });
    value = this.removeSpace(value);
    this.setState({ flag: !this.validateNumber(value) && value.length === 10 });
  };

  removeSpace(str = "") {
    return String(str).replace(/\s+/g, "")
  }

  validateNumber(number = "") {
    const regex = /^[6789]\d{9}$/;
    number = this.removeSpace(number);
    return regex.test(number) && number.length === 10;
  }

  async login() {
    if (!this.validateNumber(this.state.number)) {
      console.log("Invalid");
      return;
    }
    this.toggleSpinner(true);
    try {
      let url = this.props.location.search.replace("?url=", "");

      const customerInfo = await getCustomerInfo(this.state.code + this.removeSpace(this.state.number), url.includes("catelog_items") ? "https://" + APP_CONST.app_host + "/register?url=" + url : "");
      if (customerInfo && customerInfo.customerId) {
        await StoreDetailsToStorage(APP_CONST.user_details_key, customerInfo);
        this.setState({ number: "" });
        isUserLoggedInAction();

        const redirectTo = this.props.location.search.replace("?url=", "");
        if (redirectTo) {
          this.props.history.push(redirectTo);
        }
        if (this.props.location.pathname.includes("/register")) this.props.history.push("/");
        this.props.onLogin({ loggedIn: true, message: "Welcome back " + customerInfo.customerName });
      } else if (customerInfo && customerInfo.sendOtp) {
        this.props.onLogin({
          showConfirmOtp: this.state.code + this.removeSpace(this.state.number),
          loggedIn: false,
        });
      } else {
        this.props.onLogin({ loggedIn: false, message: "Could not sign in with " + this.state.code + this.removeSpace(this.state.number) });
      }
      this.setState({
        showSpinner: false
      })
    } catch (error) {
      console.log(error);
      this.toggleSpinner(false);
      if (error && "redirectToUrl" in error && "message" in error && "videoUrl" in error) {
        this.props.onLogin({
          videoUrl: error.videoUrl,
          messageNotSubscribed: error.message,
          redirectToUrl: error.redirectToUrl,
          loggedIn: false,
        });

      }
    }
  }

  async sendOtp() {
    try {
      const { code, number } = this.state;
      const sendOtp = await SendOtp({ code, number })
      if (sendOtp && sendOtp.OTPSent) {
        console.log("send otp");
      }
    } catch (error) {
      this.setState({ showProgressRegister: false })
    }
  }
  toggleSpinner(showSpinner = false) {
    this.setState({ showSpinner });
  }

  redirectToRegister() {
    this.props.history.push("/register");
  }


  render() {
    const { number, code, flag, showSpinner } = this.state;
    return (
      <>
        <div className="isMenu">
          <div className="m-lr-auto m-tb-10 align-center">
            <label htmlFor="mobile" className="font-bold">Mobile Number</label>
          </div>
          <div className="m-t-20 isMenu">
            <span>+{code}</span>
            <input
              id="mobile"
              name="mobile"
              type="text"
              className="login"
              value={number}
              placeholder="9999 999 999"
              onChange={this.numberUpdated}
              onKeyPress={(e) => { e.key === 'Enter' && this.login() }} />
          </div>
          {flag && <p className="align-center font-light m-b-0 m-t-5 font-80">Please enter valid mobile number</p>}
          <div className="flex" style={{ justifyContent: "center" }}>
            <button className="button-filled m-10 m-b-0" onClick={() => this.login()}>Sign In {showSpinner && <i className="gg-spinner"></i>}</button>
          </div>

          <div className="align-center m-t-10">New to Local Genie?</div>

          <div className="flex" style={{ justifyContent: "center" }}>
            <Link to={APP_CONST.routes.register + "?url=" + this.props.match.url} className="font-80 color-primary">
              Create an account
            </Link>
          </div>
        </div>
      </>
    )

  }
}
export default withRouter(LoginComponent);
