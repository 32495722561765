import APP_CONST from "./constants/appConstants";
import { showSnackBar } from "./redux/actions/SnackBarAction";

function GetDetailsFromStorage(key = APP_CONST.token_key) {
  return new Promise((resolve, reject) => {
    try {
      return resolve(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : {});
    } catch (error) {
      return reject(error);
    }
  })
}

function StoreDetailsToStorage(key, data) {
  return new Promise((resolve, reject) => {
    try {
      return resolve(localStorage.setItem(key, JSON.stringify(data)));
    } catch (error) {
      return reject(error);
    }
  })
}

function ClearDetailsFromTheStorage(key) {
  return new Promise((resolve, reject) => {
    try {
      return resolve(localStorage.removeItem(key));
    } catch (error) {
      return reject(error);
    }
  })
}

function DateFormat(date, format = `${APP_CONST.dateFormat}, ${APP_CONST.timeFormat}`) {
  if (date === 'now' || date === 'yesterday' || date === 'dayBefore' || date === 'tomorrow' || date === 'dayAfter') date = new Date();
  if (date === 'yesterday') date = date.setDate(date.getDate() - 1);
  else if (date === 'dayBefore') date = date.setDate(date.getDate() - 2);
  else if (date === 'tomorrow') date = date.setDate(date.getDate() + 1);
  else if (date === 'dayAfter') date = date.setDate(date.getDate() + 2);
  else if (typeof date === "string" && date.match(/[0-9]{2}[A-Z][a-z]{2}[0-9]{4}[0-9]{2}[0-9]{2}[0-9]{2}/)) {
    date = date.replace(/([0-9]{2})([A-Z][a-z]{2})([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})/, "$2-$1-$3 $4:$5:$6");
  }
  date = new Date(date);


  if (format) {

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const shortDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const
      HH = String(date.getHours()).padStart(2, 0),
      H = String(date.getHours()).padStart(2, 0),
      MM = String(date.getMinutes()).padStart(2, 0),
      SS = String(date.getSeconds()).padStart(2, 0),
      dd = String(date.getDate()).padStart(2, 0),
      mm = String(date.getMonth() + 1).padStart(2, 0),
      m = String(date.getMonth() + 1),
      MMM = months[date.getMonth()],
      yyyy = date.getFullYear(),
      day = days[date.getDay()],
      sday = shortDays[date.getDay()];

    format = format
      .replace(/sday/g, sday)
      .replace(/day/g, day)
      .replace(/yyyy/g, yyyy)
      .replace(/dd/g, dd)
      .replace(/mm/g, mm)
      .replace(/m/g, m)
      .replace(/MMM/g, MMM)
      .replace(/HH/g, HH)
      .replace(/MM/g, MM)
      .replace(/SS/g, SS);

    if (format.includes('H')) {
      const ampm = (Number(H) < 12 || Number(H) === 24) ? "AM" : "PM"
      let _H = String(Number(H) % 12 || 12);
      format = format.replace(/H/g, _H);
      format += ` ${ampm}`
    }

    date = format;
  }

  return date;
}

function SplitCamelCaseString(str = "") {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}

function CheckInternetInAvailable() {
  if (!window.navigator.onLine) {
    showSnackBar("Please check your internet connection!")
  }
  return window.navigator.onLine;
}

function ConvertUrlQueryToObject(str = "") {
  return Object.fromEntries(new URLSearchParams(str));
}


function RemoveScript(str = "") {
  return str.replace(/<([a-z]+) ([A-z ='"]+)>/g, "<$1>");
}

function LimitString(str = "", length = 10) {
  if (str && str.includes(" "))
    str = str.slice(0, str.indexOf(" "))
  return str.length > length ? (str.substring(0, length) + '...') : str;
}

function IsValidName(name, restritc_character_for = 2) { 
  const regex_str = `[a-zA-Z ]{${restritc_character_for},30}`
  return new RegExp(regex_str).test(name);
}
function IsValidCode(code) { return /^\+[0-9]{1,3}$/.test(code) }
function IsValidMobile(number) { return /^[789]\d{9}$/.test(number) }


export {
  GetDetailsFromStorage,
  StoreDetailsToStorage,
  ClearDetailsFromTheStorage,
  DateFormat,
  SplitCamelCaseString,
  ConvertUrlQueryToObject,
  RemoveScript,
  LimitString,
  CheckInternetInAvailable,
  IsValidName,
  IsValidCode,
  IsValidMobile,
};