import { Component } from "react";
import HeaderComponent from "../../common_components/header/Header";

import { getCatalogItemsProviderByIdService, isDeliveryAvailableForServiceProvider, getServiceProviderByBusinessCodeService } from '../../services/ServiceProvidersService';
import './CatalogItems.css';
import { addToCartService, deleteCartService, getCartDetailsService } from "../../services/CartService";
import APP_CONST from "../../constants/appConstants";
import { deleteCartAction, toggleUserConfirmationOnCart, updateCartCountAction } from "../../redux/actions/CartAction";
import store from "../../redux/Store";
import { REDUX_REDUCERS_VARIABLES } from "../../constants/reduxConstants";
import { showLoader } from "../../redux/actions/LoaderAction";
import LazyImageLoadComponent from "../../common_components/lazyImageLoad/LazyImageLoad";
import { placeOrderService } from "../../services/OrderService";
import { showSnackBar } from "../../redux/actions/SnackBarAction";
import HTTP_CONST from "../../constants/httpConstants";
import { CheckInternetInAvailable, GetDetailsFromStorage } from "../../commonFunctions";
import { isUserLoggedInAction } from "../../redux/actions/LoginAction";
import { isCustomerMappedToServiceProvider, mapCustomerToServiceProvider } from "../../services/ServiceCataloguesService";

class CatalogItemsComponent extends Component {

	state = {
		catalogsItems: {},
		firstTimeClicked: false,
		cartCount: 0,
		_items: [],
		price: 0,
		search: "",
		isVisible: false,
		isDeliver: false,
		carts_outer: {
			itemIds: [],
			items: []
		},
		isConfirmVisible: false,
		fun: "",
		isDeliverAvailableForServiceProvider: true,
		isDeliverAvailableCheckIsInProgress: false,
		showLogin: false,
		isConfirmMap: false,
		// isWelcomeOnboard: false,
		customerName: "",
		serviceProvider: {},
		serviceProviderId: 0
	}

	timeout = null;
	subscribe = null;
	subscribeState = null;

	 async componentDidMount() {
		try {
			
			showLoader(true);
			const serviceProvider = await getServiceProviderByBusinessCodeService(this.props.match.params.service_provider_id);

			console.log({serviceProvider});
			this.setState({
				serviceProviderId: serviceProvider.serviceProviderId,
				serviceProvider
			})
			isUserLoggedInAction();
			const state = store.getState();
			this.subscribeState = store.subscribe(async () => {
				const _state = store.getState();
				this.setState({
					showLogin: !_state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN] && !_state[REDUX_REDUCERS_VARIABLES.SHOW_MESSAGE],
					customerName: _state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN] ? (await GetDetailsFromStorage(APP_CONST.user_details_key))?.customerName : ""
				});
			});

			if (!state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN])
				this.props.history.push(`${this.props.match.url}?url=${encodeURI(this.props.match.url)}`)

			this.setState({ customerName: this.state.serviceProvider.serviceProviderName || (this.props && this.props.match && this.props.match.params && this.props.match.params.name) || "" })

			deleteCartAction();
			showLoader(false);
			this.checkIsMapped();
		} catch (error) {
			showLoader(false);
		}
	}

	async checkIsMapped() {
		try {
			showLoader(true);

			const isMapped = await isCustomerMappedToServiceProvider(this.state.serviceProviderId);
			showLoader(false);

			// isMapped && isMapped.serviceProviderDetails && this.setState({ serviceProvider: isMapped.serviceProviderDetails })
			if (isMapped && isMapped.status) {
				this.getItems();
			} else {
				this.toggleConfirmMapOverlay(true);
			}
		} catch (error) {
			console.log(error);
			showLoader(false);
		}
	}

	async getItems() {
		try {

			showLoader(true);
			const state = store.getState();

			let _items = await getCatalogItemsProviderByIdService(this.state.serviceProviderId);

			if (_items && _items.length) {
				this.setState({ _items });
				await this.getCatalogItems();

				this.setState({
					firstTimeClicked: state[REDUX_REDUCERS_VARIABLES.CART_COUNT] === 0 || state[REDUX_REDUCERS_VARIABLES.IS_USER_CONFIRMED_TO_CONTINUE_CART],
					cartCount: state[REDUX_REDUCERS_VARIABLES.CART_COUNT]
				});

				this.subscribe = store.subscribe(async () => {
					const _state = store.getState();
					this.setState({ cartCount: _state[REDUX_REDUCERS_VARIABLES.CART_COUNT] });
				});
			}
			showLoader(false);
		} catch (error) {
			showLoader(false);
		}
	}

	async getCatalogItems(cartDeleted = false) {
		let { _items, carts_outer } = this.state;

		let cartDetails = cartDeleted ? null : await getCartDetailsService(this.state.serviceProviderId);
		if (cartDetails && cartDetails.length) {
			cartDetails = cartDetails.reduce((r, a) => {
				r[a.itemId] = a.quantity;
				return r;
			}, {})

		} else {
			cartDetails = {};
		}

		if (_items) {
			_items = _items.reduce((r, a) => {
				if (!r[a.subCategoryName]) r[a.subCategoryName] = {
					categoryId: a.categoryId,
					categoryName: a.categoryName,
					subCategoryName: a.subCategoryName,
					subCategoryId: a.subCategoryId,
					items: [],
					isOpen: false,
					show: true
				};
				r[a.subCategoryName].items.push({
					itemId: a.itemId,
					show: true,
					itemName: a.itemName,
					price: a.price,
					subItems: (Array.isArray(a.subItems) && a.subItems.reduce((p, c, i) => {
						p +=  (!i ? c.itemName : (i !== a.subItems.length - 1 ? ", " : " and ") + c.itemName) + (c.quantity ? "("+ c.quantity + ")": "") ;
						return p;
					}, "")) || "",
					unitOfMeasure: a.unitOfMeasure,
					quantity: cartDetails[a.itemId] ? cartDetails[a.itemId] : 0,
					itemIconURL: a.itemIconURL
				});
				if (cartDetails[a.itemId]) this.formPayload(r[a.subCategoryName].items[r[a.subCategoryName].items.length - 1]);
				return r;
			}, {});

		}


		updateCartCountAction(carts_outer.itemIds.length);
		this.setState({
			catalogsItems: _items || {}
		});



	}

	isOpen(index = 0) {
		let catalogsItems = this.state.catalogsItems;
		catalogsItems[index].isOpen = !catalogsItems[index].isOpen;
		this.setState({
			catalogsItems: catalogsItems
		});
	}

	async addToCart(item, subCategoryName) {
		if (!CheckInternetInAvailable())  return;


		if (!this.state.firstTimeClicked) {
			this.toggleCartOverlay(true);
			this.setState({ firstTimeClicked: true, fun: { action: this.addToCart.bind(this), args: { item, subCategoryName } } });
			return;
		}

		this.setState({
			item: item.quantity++
		})
		item = { ...item, ...{ subCategoryName } };
		await this.updateCart(item);
	}

	async removeFromCart(item, subCategoryName) {
		if (!CheckInternetInAvailable())  return;


		if (!this.state.firstTimeClicked) {
			this.toggleCartOverlay(true);
			this.setState({ firstTimeClicked: true, fun: { action: this.removeFromCart.bind(this), args: { item, subCategoryName } } });
			return;
		}
		if (item.quantity > 0) this.setState({
			item: item.quantity--
		});
		item = { ...item, ...{ subCategoryName } };
		await this.updateCart(item);
	}

	async updateCart(item) {
		const { carts_outer } = this.state;
		if (this.timeout) clearTimeout(this.timeout);
		let _cartObj = {
			...item,
			serviceProviderId: this.state.serviceProviderId
		};
		await this.formPayload(item);
		this.timeout = setTimeout(async () => {
			await addToCartService(_cartObj);
		}, 300);
		updateCartCountAction(carts_outer.itemIds.length);
	}

	async order() {

		if (this.state.isDeliverAvailableCheckIsInProgress) return;

		showLoader(true);

		const orderPlaced = await placeOrderService({ serviceProviderId: this.state.serviceProviderId, isDeliver: this.state.isDeliver && this.state.isDeliverAvailableForServiceProvider });

		if (orderPlaced && orderPlaced.orderId) {
			showSnackBar("Order Placed");
			this.props.history.push(APP_CONST.routes.order_details.replace(":order_id", orderPlaced.orderId))
		}
		showLoader(false);
	}

	async chooseDeliveryType(type) {
		this.setState({
			isDeliver: type === 'D' && this.state.isDeliverAvailableForServiceProvider
		});
	}

	toggleOrderOverlay(isVisible = false) {
		this.setState({ isVisible });
	}

	formPayload(_item = {}) {
		let { carts_outer } = this.state;
		if (carts_outer.itemIds.includes(_item.itemId)) {
			if (_item.quantity === 0) {
				carts_outer.items = carts_outer.items.filter(item => item.itemId !== _item.itemId);
				carts_outer.itemIds = carts_outer.itemIds.filter(item => item !== _item.itemId);
			} else {
				carts_outer.items[carts_outer.itemIds.indexOf(_item.itemId)] = _item
			}
		} else {
			if (_item.quantity) {
				carts_outer.itemIds.push(_item.itemId);
				carts_outer.items.push(_item);
			}
		}
		this.setTotalPrice();
	}

	setTotalPrice() {
		let { carts_outer } = this.state;
		let totalPrice = carts_outer.items.reduce((p, c) => p + (c.quantity * c.price), 0);
		this.setState({ price: totalPrice });
	}

	onSearchValueChanged(e) {
		this.setState({ search: e.target.value });
		const textToSearch = (e.target.value).toLowerCase();
		const { catalogsItems } = this.state;

		Object.keys(catalogsItems).forEach((catalogItem) => {
			let _catalogItem = catalogItem.toLowerCase();
			let childContains = false, parentContains = false;
			if (_catalogItem.includes(textToSearch)) {
				parentContains = true;
			}

			catalogsItems[catalogItem].items = catalogsItems[catalogItem].items.map((item) => {
				let _item = item.itemName.toLowerCase()
				if (_item.includes(textToSearch)) {
					item.show = true;
					childContains = true;
				} else {
					item.show = false || parentContains;
				}
				return item;
			});

			catalogsItems[catalogItem].show = parentContains || childContains;
			catalogsItems[catalogItem].isOpen = (parentContains || childContains) && textToSearch;

		})

		this.setState({ catalogsItems })
	}


	async clearCart() {
		await deleteCartService(this.state.serviceProviderId);
		toggleUserConfirmationOnCart(false);
		this.setState({ carts_outer: { items: [], itemIds: [] } })
		if (this.state.fun) {
			// let { item, subCategoryName } = this.state.fun.args
			// await this.state.fun.action(item, subCategoryName);
			this.setState({ fun: "" });
		}
		updateCartCountAction(0);
		// setTimeout(async () => {
		await this.getCatalogItems();
		await this.setTotalPrice();
		this.toggleCartOverlay();
		// }, 1000)
	}

	async continue() {
		if (this.props.match.params && this.state.serviceProviderId) {
			toggleUserConfirmationOnCart(true);
			this.toggleCartOverlay();
			if (this.state.fun) {
				let { item, subCategoryName } = this.state.fun.args
				await this.state.fun.action(item, subCategoryName);
				this.setState({ fun: "" });
			}
		}
	}

	toggleCartOverlay(isConfirmVisible = false) {
		this.setState({
			isConfirmVisible
		})
	}

	toggleConfirmMapOverlay(isConfirmMap = false) {
		this.setState({
			isConfirmMap
		});
	}

	toggleWelcomeOnboardOverlay(isWelcomeOnboard = false) {
		if (isWelcomeOnboard) {
			showSnackBar("Successfully added!")
		}
		// this.setState({
		// 	isWelcomeOnboard
		// });
	}

	async confirmMap() {
		try {
			this.setState({
				isDeliverAvailableCheckIsInProgress: true
			})
			let mapped = await mapCustomerToServiceProvider(this.state.serviceProviderId);

			this.setState({
				isDeliverAvailableCheckIsInProgress: false
			})
			if (mapped && mapped.rowCount) {
				this.getItems();
				this.toggleWelcomeOnboardOverlay(true);
			}
			this.toggleConfirmMapOverlay();
		} catch (error) {
			this.setState({
				isDeliverAvailableCheckIsInProgress: true
			})

		}
	}

	async checkServiceProviderIsDeliverOption() {
		try {

			const _deliverOption = await isDeliveryAvailableForServiceProvider(this.state.serviceProviderId);
			this.setState({
				isDeliverAvailableForServiceProvider: _deliverOption,
				isDeliverAvailableCheckIsInProgress: false,
				isDeliver: this.state.isDeliver && _deliverOption
			});
		} catch (error) {
		}
	}

	render() {
		const { catalogsItems, price, search, isVisible, isConfirmVisible, isDeliver, cartCount, isDeliverAvailableForServiceProvider, isDeliverAvailableCheckIsInProgress, showLogin, isConfirmMap, customerName } = this.state;

		return (
			<>

				<HeaderComponent title={this.state.serviceProvider.serviceProviderName || this.props.match.params.name} showLogin={showLogin} onChanged={(e) => this.checkIsMapped()}></HeaderComponent>

				{
					isConfirmVisible &&
					<div className="overlay-popup" >
						<div className="card align-center" >
							<p className="font-bold">You have items in the basket</p>
							<p className="m-tb-20">Do you want to continue with same basket?</p>
							<div className="flex" style={{ justifyContent: "center" }}>
								<button className="button-border m-lr-10" onClick={() => this.clearCart()}>No</button>
								<button className="button-filled m-lr-10" onClick={() => this.continue()}>Yes</button>
							</div>
						</div>

					</div>
				}
				{
					isVisible &&
					<div className="overlay-popup" >
						<div className="card align-center" >
							<p className="font-bold m-b-10">Choose delivery type</p>
							<div className={"flex m-tb-20 m-t-10" + (!isDeliverAvailableForServiceProvider ? " m-b-5" : "")} style={{ justifyContent: "center" }}>
								<span className={"color-white chip chip-cart" + (isDeliver ? " selected border-1" : "") + (!isDeliverAvailableForServiceProvider ? " disabled" : "")} onClick={() => this.chooseDeliveryType("D")}>Delivery</span>
								<span className={"color-white chip chip-cart" + (!isDeliver ? " selected border-1" : "")} onClick={() => this.chooseDeliveryType("P")}>Pickup</span>
							</div>
							{
								!isDeliverAvailableForServiceProvider && <span className="font-80" style={{ color: '#ff0033' }}>{HTTP_CONST.MESSAGE.DELIVERY_NOT_AVAILABLE}</span>
							}
							<div className="flex m-t-10 p-10" style={{ justifyContent: "center" }}>
								<button className="button-border m-lr-10" onClick={() => this.toggleOrderOverlay()}>Cancel</button>
								<button className="button-filled m-lr-10" disabled={isDeliverAvailableCheckIsInProgress} onClick={() => this.order()}>Confirm{isDeliverAvailableCheckIsInProgress && <i className="gg-spinner"></i>}</button>
							</div>
						</div>

					</div>
				}

				<div className='content m-b-50'>
					{
						(Object.keys(catalogsItems).length !== 0) &&
						<div className="card search">
							<input className="block m-lr-auto search-item align-left" value={search} placeholder="Search for products..." onChange={(e) => this.onSearchValueChanged(e)} />
							{search && <i className="gg-close" onClick={(e) => search && this.onSearchValueChanged({ target: { value: "" } })}></i>}
						</div>
					}
					<div className="grid">
						{
							Object.keys(catalogsItems).map((itemName, i) => {
								return (
									catalogsItems[itemName].show ?
										<ul className="card m-b-0" key={"div" + i + "_"}>
											<li key={"listItem_" + i} onClick={() => this.isOpen(itemName)} style={{ padding: "0px", cursor: "pointer" }}>
												<div className="flex main m-0 w-100 text-capitalize">
													<span key={"text" + i + "_"} style={{ color: "#082e53", fontWeight: 600 }}>{itemName}</span>
													<i className={catalogsItems[itemName].isOpen ? "gg-chevron-right" : "gg-chevron-down"}></i>
												</div>
												{
													catalogsItems[itemName].items.map((item, j) => {
														if (catalogsItems[itemName].isOpen && item.show) {
															return (
																<div className="flex main m-lr-5 w-100" key={"div" + i + "_" + j} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
																	<LazyImageLoadComponent allowfullscreen='true' class="item-img" src={item.itemIconURL} targetWindow="false"></LazyImageLoadComponent>
																	<div className="w-100">
																		<p className="m-0 m-t-20 font-bold" key={"text_" + item.itemId}>{item.itemName}</p>
																		<p className="m-t-10 font-80 color-light">
																			{
																				item.price ?
																					<><i className="inr-sign"></i> &nbsp;<span key={"text_" + item.customerId + j}>{item.price}</span></>
																					: ""
																			}
																		</p>
																		{item.subItems && <p className="font-80">{item.subItems}</p>}
																	</div>
																	{
																		item.quantity ?
																			<div className="flex" key={"div_" + item.itemId + "-" + j}>
																				<i className="gg-math-minus"
																					onClick={(e) => { e.stopPropagation(); this.removeFromCart(item, itemName) }}></i>
																				<input type="number" key={'input_' + item.customerId + j} disabled value={item.quantity} onChange={this.handleChange} className="quantity p-0 border-0" />
																				<i className="gg-math-plus" onClick={(e) => { e.stopPropagation(); this.addToCart(item, itemName) }}></i>
																			</div> :
																			<div className="add-button" onClick={(e) => { e.stopPropagation(); this.addToCart(item, itemName) }}><span>ADD <sup className="font-bold">+</sup></span></div>
																	}
																</div>
															)
														} else {
															return (<span key={i + "_" + j}></span>)
														}
													})
												}
											</li>
										</ul>
										: "");
							})
						}
						{!Object.keys(catalogsItems).length && <p className="align-center">No Catalogue Items</p>}
					</div>
				</div>
				{
					cartCount &&
					<div className="footer flex main w-100 m-0 p-0 background-white">
						<p className="p-lr-10 color-light" style={{ marginLeft: '10px' }}>{price ? <>Total: <i className="inr-sign"></i> <span>{price}</span></> : ""}</p>
						<button className="button-filled" disabled={!cartCount} style={{ marginRight: "15px" }} onClick={(e) => { this.checkServiceProviderIsDeliverOption(); this.toggleOrderOverlay(true) }}>Order</button>
					</div>
				}

				{
					isConfirmMap &&
					<div className="overlay-popup" >
						<div className="card" >
							<div className="m-b-10 p-lr-10">
								<p>Dear <span className="font-bold">{customerName}</span>,</p>
								<p>Please confirm to start enjoying our service!</p>

								<p className="m-b-0">At your service,</p>
								<p className="m-0 font-bold">{this.state.serviceProvider.serviceProviderName || this.props.match.params.name}</p>
								<p className="font-80 align-center">Powered by <b>Local Genie</b> </p>
							</div>
							{/* new_vendor_confirmation */}
							<div className="flex m-t-10 p-10" style={{ justifyContent: "center" }}>
								<button className="button-border m-lr-10" onClick={() => this.toggleConfirmMapOverlay()}>Cancel</button>
								<button className="button-filled m-lr-10" disabled={isDeliverAvailableCheckIsInProgress} onClick={() => this.confirmMap()}>Confirm{isDeliverAvailableCheckIsInProgress && <i className="gg-spinner"></i>}</button>
							</div>
						</div>

					</div>
				}

				{/* {
					isWelcomeOnboard &&
					<div className="overlay-popup" >
						<div className="card align-center" >
							<p className="font-bold m-b-10">Welcome onbaod! You can now enjoy the service provided by {this.props.match.params.name}</p>
							<div className="flex m-t-10 p-10" style={{ justifyContent: "center" }}>
								<button className="button-filled m-lr-10" onClick={() => this.toggleWelcomeOnboardOverlay()}>OK</button>
							</div>
						</div>

					</div>
				} */}

			</>
		);
	}

	componentWillUnmount() {
		if (this.subscribe)
			this.subscribe();
		if (this.subscribeState)
			this.subscribeState();
		if (this.timeout) {
			clearTimeout();
		}
	}
}

export default CatalogItemsComponent;
