import React, { Component } from "react";
import './AddServiceProviderUsingBusinessId.css';

import APP_CONST from "../../constants/appConstants";
import LazyImageLoadComponent from "../../common_components/lazyImageLoad/LazyImageLoad";
import QrCodeSample from "../../images/qr_code_sample.png";
import { getServiceProviderByBusinessCodeService } from "../../services/ServiceProvidersService";

class AddServiceProviderUsingBusinessIdComponent extends Component {

    state = {
        title: "Enter Vendor Business Code",
        businessId: "",
        showSpinner: false,
        errMsg: ""
    }

    toggleSpinner(showSpinner = true) {
        this.setState({ showSpinner });
    }

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    close() {
        // console.log(this.props, this.params, this.state.clickFn);
        return this.props.clickFn && typeof this.props.clickFn === "function" ? this.props.clickFn() : this.props.history.goBack();
    }

    isValidateBusinessId() {
        const { businessId } = this.state;

        if (!businessId) return;

        //return businessId.match(/[0-9]{1,7}[A-z]{3}/);
        return businessId.match(/[A-z]{3}[0-9]{1,7}/);
    }

    async customerServiceProviderMap() {

        try {
            this.setState({ errMsg: "" });
            if (!this.isValidateBusinessId()) {
                this.setState({ errMsg: "Vendor Business Code should start with number and ends with two alphabets. Ex:- 12EAB, 1234567ADS" });
                return;
            }
            this.toggleSpinner(true);

            const serviceProvider = await getServiceProviderByBusinessCodeService(this.state.businessId);
            console.log(serviceProvider);

            if (!serviceProvider || !serviceProvider.serviceProviderId) {
                this.setState({ errMsg: `Business code is incorrect please verify and try again!` });
                this.toggleSpinner(false);
                return;
            }


            setTimeout(() => {
                this.props.history.push(APP_CONST.routes.catelog_items.replace(":service_provider_id", serviceProvider.businessCode));
            }, 500)
        } catch (error) {
            this.setState({ errMsg: error.message })
            this.toggleSpinner(false);
        }
    }

    render() {

        const { businessId, showSpinner, errMsg } = this.state;

        return (
            <>
                <div className="qr-container">
                    <div className="overlay business-overlay">
                        <span className="qr-close" onClick={() => this.close()}><i className="gg-arrow-left cursor-pointer color-primary"></i></span>
                        <div className="middle">
                            <LazyImageLoadComponent src={QrCodeSample}></LazyImageLoadComponent>
                            <div className="business-id">
                                <label>BUSINESS CODE</label>
                                <div style={{ "position": "relative" }}>
                                    <input type="text" maxLength="10" minLength="10" placeholder="XXX0000000" value={businessId} onChange={(e) => this.setState({ businessId: e.target.value })}></input>
                                    <button className="button-filled add-service-button" disabled={showSpinner || !this.isValidateBusinessId()} onClick={(e) => this.customerServiceProviderMap()}>{!showSpinner && <i className="gg-chevron-right"></i>} {showSpinner && <i className="gg-spinner"></i>}</button>
                                </div>
                            </div>
                            <p className="font-80 business-id-error-msg">{errMsg}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    };
}

export default AddServiceProviderUsingBusinessIdComponent;