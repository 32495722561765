import { Component } from "react";
import HeaderComponent from "../../common_components/header/Header";

import CardServiceProviderComponent from "../../common_components/card/CardServiceProvider";
import { getServicesProviderByIdService } from '../../services/ServiceProvidersService';
import {
	Link,
} from "react-router-dom";
import './ServiceProviders.css';
import APP_CONST from "../../constants/appConstants";
import { REDUX_REDUCERS_VARIABLES } from "../../constants/reduxConstants";
import { toggleUserConfirmationOnCart } from "../../redux/actions/CartAction";
import store from "../../redux/Store";
import HTTP_CONST from "../../constants/httpConstants";
import { showLoader } from "../../redux/actions/LoaderAction";
import { RemoveScript } from "../../commonFunctions";
import LazyImageLoadComponent from "../../common_components/lazyImageLoad/LazyImageLoad";

class ServiceProvidersComponent extends Component {

	state = {
		catelogs: [],
		showLogin: false,
		categoryDetails: {},
		messageDetails: {}
	}

	async componentDidMount() {
		this.getAllServiceCatalogues();
	}

	async getAllServiceCatalogues() {
		showLoader(true);

		try {
			if (store.getState()[REDUX_REDUCERS_VARIABLES.IS_USER_CONFIRMED_TO_CONTINUE_CART])
				toggleUserConfirmationOnCart(false);
			const _services = await getServicesProviderByIdService(this.props.match.params.category_id);
			this.setState({ catelogs: _services.serviceProviders, categoryDetails:_services.categoryDetails, showLogin: false });
		} catch (error) {
			console.log(error);
			if (error && error.code === HTTP_CONST.CODE.UNAUTHORISED) {
				this.setState({ showLogin: true })
			}
			if(error && error.code === HTTP_CONST.CODE.NOT_FOUND) {
				if(error.messageDetails && error.messageDetails.message) {
					error.messageDetails.message =String(error.messageDetails.message).replace("{{CATEGORY}}", error.categoryDetails.categoryName);
				}
				this.setState({ messageDetails: error.messageDetails, categoryDetails:error.categoryDetails });
			}
		}
		showLoader(false);
	}

	render() {
		const { catelogs, showLogin, categoryDetails,messageDetails } = this.state;

		return (
			<>
				<HeaderComponent title={categoryDetails.categoryName || this.props.match.params.name} showLogin={showLogin} onChanged={(e) => this.getAllServiceCatalogues()}></HeaderComponent>
				<div className="content">
					<div className="grid">
						{
							catelogs && catelogs.length ?
								catelogs.map((catelog, i) => {
									return (
										<Link to={APP_CONST.routes.catelog_items.replace(":service_provider_id", catelog.businessCode)} key={i}>
											<CardServiceProviderComponent
												img={catelog.serviceProviderIconURL}
												class="flex-start"
												imgClass="w-25 m-tb-auto"
												containerClass="m-b-0"
												key={catelog.serviceProviderId}
												title={catelog.serviceProviderName}
												address={catelog.serviceProviderAddress}
												pincode={catelog.serviceProviderPinCode}>
											</CardServiceProviderComponent>
										</Link>
									);
								}) :
								<div className="center-screen align-center">
									<div className="w-50 m-lr-auto">
										<LazyImageLoadComponent src={categoryDetails.categoryIconURL} targetWindow="false"></LazyImageLoadComponent>
									</div>
									<div dangerouslySetInnerHTML={{ __html: RemoveScript(messageDetails.message) }} >
									</div>
								</div>
						}
					</div>
				</div>
			</>
		);
	}
}

export default ServiceProvidersComponent;