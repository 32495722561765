import { CheckInternetInAvailable } from "../commonFunctions";
import APP_CONST from "../constants/appConstants";

const URLS = {
    CUSTOMER_URL: process.env.REACT_APP_API_URL,
    MANAGE_CUSTOMER_URL: process.env.REACT_APP_CUSTOMER_MANAGE_URL,
    MANAGE_SERVICE_PROVIDER_URL: process.env.REACT_APP_SERVICE_PROVIDER_MANAGE_URL,
}

function GetApiCall(url = "", params = {}, API_URL = APP_CONST.API_TYPES.CUSTOMER_URL) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!CheckInternetInAvailable())  return reject({message: ""});
            url = `${URLS[API_URL]}${url}${params && Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : ""}`;

            const response = await fetch(url);

            if (response.status === 200)
                return resolve(response.json());
            else
                return reject({ ...{ code: response.status, message: response.statusText }, ...await response.json() })
        } catch (error) {
            console.log(error);
            return reject(error);
        }
    })
}

function PatchApiCall(url = "", body = {}, API_URL = APP_CONST.API_TYPES.CUSTOMER_URL) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!CheckInternetInAvailable())  return reject({message: ""});


            url = `${URLS[API_URL]}${url}`
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            }

            const response = await fetch(url, requestOptions);

            if (response.status === 200)
                return resolve(response.json());
            else
                return reject({ ...{ code: response.status, message: response.statusText }, ...await response.json() })
        } catch (error) {
            return reject(error);
        }
    })
}

function PostApiCall(url = "", body = {}, API_URL = APP_CONST.API_TYPES.CUSTOMER_URL) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!CheckInternetInAvailable())  return reject({message: ""});

            url = `${URLS[API_URL]}${url}`
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            }

            const response = await fetch(url, requestOptions);

            if (response.status === 200)
                return resolve(response.json());
            else
                return reject({ ...{ code: response.status, message: response.statusText }, ...await response.json() })

        } catch (error) {
            console.log(error);
            return reject(error);
        }
    })
}

export {
    GetApiCall,
    PatchApiCall,
    PostApiCall
}