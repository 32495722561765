import './Loader.css';
import { Component } from 'react';
import store from '../../redux/Store';
import { REDUX_REDUCERS_VARIABLES } from '../../constants/reduxConstants';

class LoaderComponent extends Component {

  subscribe = null;

  state = {
    showLoader: false
  }


  subscribe
  async componentDidMount() {
    this.subscribe = store.subscribe(() => {
      this.setState({ showLoader: store.getState()[REDUX_REDUCERS_VARIABLES.SHOW_LOADER] })
    });

  }

  render() {
    const { showLoader } = this.state;
    return (
      <>
        {
          showLoader &&
          <div className="loader grid" style={{ display: showLoader ? "block" : "none" }} >
            <div className="card">
              <div className="text-line"> </div>
              <div className="text-line"></div>
              <div className="text-line"></div>
            </div>
            <div className="card">
              <div className="text-line"> </div>
              <div className="text-line"></div>
              <div className="text-line"></div>
            </div>
            <div className="card">
              <div className="text-line"> </div>
              <div className="text-line"></div>
              <div className="text-line"></div>
            </div>
          </div>
        }
      </>
    );
  }

  componentWillUnmount() {
    if (this.subscribe)
      this.subscribe();
  }
}

export default LoaderComponent;

