import { REDUX_ACTION } from "../../constants/reduxConstants";
import store from "../Store";

export const isUserLoggedInAction = () => store.dispatch({
    type: REDUX_ACTION.IS_USER_LOGGED_IN,
    payload: {}
});

export const resetState = () => store.dispatch({
    type: REDUX_ACTION.RESET_STATE,
    payload: {}
});