const HTTP_CONST = {
    CODE: {
        UNAUTHORISED: 401,
        BAD_REQUEST: 400,
        NOT_FOUND: 404,
    },
    MESSAGE: {
        DELIVERY_NOT_AVAILABLE: "Delivery not available. Confirm for pickup!",
        NO_ORDERS: "No orders",
        PLEASE_TRY_AGAIN_LATER: "Please try again later!!!",
    }
}

export default HTTP_CONST;