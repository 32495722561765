import "./Card.css";
import LazyImageLoadComponent from "../lazyImageLoad/LazyImageLoad";
import { RemoveScript } from "../../commonFunctions";

function CardComponent(params) {
  function handleClick(event) {
    return params.clickFn ? params.clickFn() : false;
  }

  return (
    <div className={"card" + (params.containerClass ?" "+ params.containerClass : "")} onClick={handleClick}>
      <div className={params.class ? params.class : ""}>
        {params.img && <LazyImageLoadComponent class={params.imgClass} src={params.img} targetWindow="false"></LazyImageLoadComponent>}
        <p className="card-title font-bold w-100 m-0"  dangerouslySetInnerHTML={{ __html: RemoveScript(params.title) }} ></p>
      </div>
    </div>
  );
}

export default CardComponent;
