import React, { Component } from "react";
import { DateFormat } from "../../commonFunctions";
import "./Calendar.css"

const today = new Date();

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const shortDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
class CalendarComponent extends Component {

    state = {
        selected_date: "",
        selected_month: "Jan",
        selected_year: "2021",
        dates: [],
        range: false,
        rangeStart: "",
        rangeEnd: "",
        disableEndDate: "",
        monthView: false,
        yearView: false,
        years: [],
        showCalendar: false
    }


    async componentDidMount() {
        this.setState({
            selected_date: DateFormat("now", ""),
            selected_month: Number(DateFormat("now", "mm")),
            selected_year: Number(DateFormat("now", "yyyy")),
            disableEndDate: DateFormat("tomorrow", ""),
            range: !!this.props.range,
            showCalendar: this.props.show,
        }, this.setDates);

    }


    getDaysInMonth(month, year) {
        month = Number(month) - 1;
        year = Number(year);
        let startDate = new Date(year, month, 1);
        let endDate = new Date(year, month + 1, 1);
        startDate.setDate(startDate.getDate() - startDate.getDay());
        endDate.setDate(endDate.getDate() - 1);
        let remainingDays = 7 - endDate.getDay();
        endDate.setDate(endDate.getDate() + (remainingDays - 1));

        return this.getDatesBetweenTwoDates(startDate, endDate);
    }

    getDatesBetweenTwoDates(startDate, endDate) {
        let days = [];
        let _startDate = new Date(startDate);
        while (_startDate <= endDate) {
            days.push(new Date(_startDate));
            _startDate.setDate(_startDate.getDate() + 1);
        }
        return days;
    }

    getDayOfWeek(date) {
        return new Date(date).getDay()
    }

    static getDerivedStateFromProps(props, state) {
        const { showCalendar } = state;
        if (showCalendar !== props.show)
            return { showCalendar: props.show };
        return {};
    }

    dateSelected(date) {
        date = new Date(date);
        const { range, rangeStart, rangeEnd } = this.state;
        let obj = {
            selected_date: date
        }
        if (range) {
            if (rangeEnd || !rangeStart) {
                obj.rangeStart = date;
                obj.rangeEnd = "";
            } else {
                obj.rangeEnd = date;
            }
            if (obj.rangeEnd && obj.rangeEnd <= rangeStart) {
                let temp = obj.rangeStart;
                obj.rangeStart = obj.rangeEnd;
                obj.rangeEnd = temp;
            }
        }
        this.setState(obj, this.sendSelectedDateBack);


    }

    sendSelectedDateBack() {
        const { range, rangeStart, rangeEnd, selected_date } = this.state;

        if (range && rangeStart && rangeEnd) {
            this.props.onDateSelected(this.getDatesBetweenTwoDates(rangeStart, rangeEnd))
        } else if (!range) {
            this.props.onDateSelected(selected_date);
        }
    }

    dateInRange(date) {
        let { range, rangeStart, rangeEnd } = this.state;


        if (!range || !rangeEnd || !rangeStart) {
            return false;
        }

        return date <= rangeEnd && date >= rangeStart;

    }

    clickPrevNext(type = "p") {
        let { selected_month, selected_year, yearView } = this.state;
        if (!yearView) {
            if (type === "p") {
                selected_month--;
            } else {
                selected_month++;
            }

            if (!selected_month) {
                selected_month = 12;
                selected_year--;
            } else if (selected_month === 13) {
                selected_month = 1;
                selected_year++;
            }
        } else {
            if (type === "p") {
                selected_year -= 10;
            } else {
                selected_year += 10;
            }
            this.getYears(selected_year);
        }
        this.setState({
            selected_month,
            selected_year
        }, this.setDates)
    }

    setDates() {
        const { selected_month, selected_year } = this.state;

        this.setState({
            dates: this.getDaysInMonth(selected_month, selected_year)
        })
    }

    selectMonth() {
        const { monthView } = this.state;


        this.setState({
            monthView: !monthView,
            yearView: false
        });
    }

    selectYear() {
        const { yearView,selected_year } = this.state;
        this.getYears(selected_year);
        this.setState({
            monthView: false,
            yearView: !yearView
        })
    }

    getYears(selected_year) {

        let startYear = Math.floor(selected_year / 10) * 10, years = [];
        for (let i = 0; i < 10; i++) {
            years.push(startYear + i);
        }
        this.setState({ years });
    }

    monthSelected(index) {
        this.setState({ selected_month: index }, this.setDates); this.selectMonth();
    }

    yearSelected(year) {
        this.setState({ selected_year: year }, this.setDates); this.selectYear();
    }

    render() {
        const { selected_date, selected_month, selected_year, dates, monthView, yearView, years, showCalendar } = this.state;
        return (
            <>
                <div className="overlay-popup" style={{ display: showCalendar ? "block" : "none" }}>
                    <div className="card calender">
                        <div className="flex main m-0 w-100">
                            <span className="font-bold" onClick={() => this.clickPrevNext("p")}>{"<"}</span>
                            <div className="flex font-bold">
                                <p className="p-10" onClick={() => this.selectMonth()}>{months[selected_month - 1]}</p>
                                <p className="p-10" onClick={() => this.selectYear()}>{selected_year}</p>
                            </div>
                            <span className="font-bold" onClick={() => this.clickPrevNext("n")}>{">"}</span>
                        </div>
                        {
                            !monthView && !yearView &&
                            <div className="wrapper">

                                {
                                    shortDays.map((day) => {
                                        return (
                                            <div className="header" key={day}>{day}</div>
                                        )
                                    })}
                                {
                                    dates.map((date) => {
                                        return (
                                            <div
                                                key={String(date)}
                                                className={
                                                    (Number(DateFormat(date, "mm")) !== selected_month ? "color-light" : "font-bold") +
                                                    (DateFormat(date, "ddmm") === DateFormat(today, "ddmm") ? " today" : "") +
                                                    (DateFormat(date, "ddmm") === DateFormat(selected_date, "ddmm") ? " selected" : "") +
                                                    (this.dateInRange(date) ? " selected" : "")
                                                } onClick={() => this.dateSelected(date)}>
                                                <span>{DateFormat(date, "dd")}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            yearView &&
                            <div className="wrapper year">
                                {
                                    years.map((year) => {
                                        return (
                                            <div
                                                className={"font-bold " + (selected_year === year ? "today" : "")}
                                                key={year}
                                                onClick={() => { this.yearSelected(year) }}>
                                                <span>{year}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {
                            monthView &&
                            <div className="wrapper month">
                                {months.map((month, i) => {
                                    return (
                                        <div
                                            className={"font-bold " + (selected_month === i + 1 ? "today" : "")}
                                            key={month}
                                            onClick={() => { this.monthSelected(i + 1) }}>
                                            <span>{month}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }

    // componentWillUnmount() {
    // }
}

export default CalendarComponent;