import { ClearDetailsFromTheStorage } from "../commonFunctions";
import API_CONST from "../constants/apiConstants";
import APP_CONST from "../constants/appConstants";
import { REDUX_REDUCERS_VARIABLES } from "../constants/reduxConstants";
import { isUserLoggedInAction, resetState } from "../redux/actions/LoginAction";
import store from "../redux/Store";
import { GetApiCall } from "./ApiCallRequestService";


function getCustomerInfo(customerMobile, sourceURL) {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve({customerId: 1});
      // }


      let services = await GetApiCall(API_CONST.getCustomerInfo, { customerMobile ,sourceURL});

      return resolve(services);
    } catch (error) {
      console.log(error);
      return reject(error);
    }
  })
}


function isCustomerLoggedIn(props = {}, redirectTo = "") {
  return new Promise(async (resolve, reject) => {

    try {

      isUserLoggedInAction();
      const _state = store.getState();
      if (redirectTo)
        props.history.push(`${props.match.url}?url=${encodeURI(redirectTo)}`)
      if (_state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN] && redirectTo) {
        props.history.push(redirectTo);
      } else if (!_state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN]) {
        console.log("entered");
      } else {
        return resolve(true)
      }
    } catch (error) {
      return reject(error)
    }
  })
}

function logout() {
  return new Promise(async (resolve, reject) => {
    try {

      await ClearDetailsFromTheStorage(APP_CONST.user_details_key);
      await ClearDetailsFromTheStorage(APP_CONST.token_key);
      sessionStorage.removeItem(APP_CONST.store_key);
      resetState();

      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  })
}



export {
  getCustomerInfo,
  logout,
  isCustomerLoggedIn
};