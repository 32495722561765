import { REDUX_ACTION } from "../../constants/reduxConstants";
import store from "../Store";

export const updateCartCountAction = count => store.dispatch({
  type: REDUX_ACTION.UPDATE_CART_COUNT,
  payload: { count }
});

export const deleteCartAction = (serviceProviderId) => store.dispatch({
  type: REDUX_ACTION.DELETE_CART,
  payload: { serviceProviderId }
});

export const toggleUserConfirmationOnCart = (confirm = true) => store.dispatch({
  type: REDUX_ACTION.TOGGLE_USER_CONFIRMATION_ON_CART,
  payload: {
    confirm
  }
});

export const changeCartReseted = (CART_RESETED = false) => store.dispatch({
  type: REDUX_ACTION.CHANGE_CART_RESETED,
  payload: {
    CART_RESETED
  }
});
