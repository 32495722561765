import React, { useState, useEffect } from 'react';


const PromptForPWAInstall = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    // const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = e => {
            e.preventDefault();
            // setSupportsPWA(true);
            // setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);
        return () => window.removeEventListener("transitionend", handler);
    }, []);

    // const installPwa = e => {
    //     e.preventDefault();
    //     if (promptInstall) {
    //         promptInstall.prompt();
    //     } else {
    //         return;
    //     };
    //     setSupportsPWA(false);
    // };

    const cancel=(e) => {
        setSupportsPWA(false);
    }

    return (

        <> {
            supportsPWA &&
            <div className="snack-bar" >
                <div className="flex">
                    <p className="m-0">Do you want add Local Genie to your home screen?</p>
                    {/* <button style={{marginLeft: "10px", cursor: "pointer"}} onClick={(e) => installPwa(e)}>Yes</button> */}
                    <button style={{marginLeft: "10px", cursor: "pointer"}} onClick={(e) => cancel(e)}>No</button></div>
            </div>
        }
        </>
    )
};

export default PromptForPWAInstall;