import API_CONST from "../constants/apiConstants";
import APP_CONST from "../constants/appConstants";
import { GetApiCall, PostApiCall } from "./ApiCallRequestService";

function SendOtp({ number = "", code = "" }) {
    return new Promise(async (resolve, reject) => {
        try {
            const otpSent = await GetApiCall(`${API_CONST.sendOtp}?mobileNumber=${code}${number.replace(/ /g, "")}`);
            return resolve(otpSent);
        }
        catch (error) {
            return reject(error);
        }
    });
}

function ReSendOtp({ number, code }) {
    return new Promise(async (resolve, reject) => {
        try {
            const otpSent = await GetApiCall(`${API_CONST.reSendOtp}?mobileNumber=${code}${number.replace(/ /g, "")}`);
            return resolve(otpSent);
        }
        catch (error) {
            return reject(error);
        }
    });
}

function ConfirmOtp({ otp, code, number }) {
    return new Promise(async (resolve, reject) => {
        try {
            const otpSent = await GetApiCall(`${API_CONST.confirmOtp}?mobileNumber=${code}${number.replace(/ /g, "")}&otp=${otp}`);
            return resolve(otpSent);
        }
        catch (error) {
            return reject(error);
        }
    });
}

function Register({ customerName, mobileNumber, pinCode, address, source, serviceProviderId, lastName }) {
    return new Promise(async (resolve, reject) => {
        try {
            customerName = String(customerName).trim();
            const registered = await PostApiCall(API_CONST.register, { mobileNumber, customerName, pinCode, address, source, serviceProviderId, lastName }, APP_CONST.API_TYPES.MANAGE_CUSTOMER_URL);
            if (registered) {
                return resolve(registered);
            } else {
                return reject({ message: "Could not register" })
            }
        }
        catch (error) {
            console.log(error);
            return reject(error);
        }
    });
}

function CheckCustomerExists(mobileNumber, code = "91") {
    return new Promise(async (resolve, reject) => {
        try {
            mobileNumber = String(code + mobileNumber).replace(/\s+/g, "");
            const Exists = await GetApiCall(`${API_CONST.checkCustomerExists}?${mobileNumber}`, APP_CONST.API_TYPES.MANAGE_CUSTOMER_URL);
            if (Exists) {
                return resolve(Exists);
            } else {
                return reject({ message: "Could not check customer exists" })
            }
        }
        catch (error) {
            console.log(error);
            return reject(error);
        }
    });
}

export {
    ConfirmOtp,
    SendOtp,
    ReSendOtp,
    Register,
    CheckCustomerExists
};