

function GetPosition() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(resp => {
                return resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude, resp });
            },
                err => {
                    return reject(err.message);
                });
        } else {
            return reject("Geolocation is not supported by this browser.");

        }
    });

}

function WatchPosition() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(resp => {
                return resolve({ long: resp.coords.longitude, lat: resp.coords.latitude, resp });
            },
                err => {
                    return reject(err.message);
                });
        } else {
            return reject("Geolocation is not supported by this browser.");

        }
    });

}

function ClearWatch(watchId) {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.clearWatch(watchId);
            return resolve(true);
        } else {
            return reject("Geolocation is not supported by this browser.");
        }
    });

}

export {
    ClearWatch,
    WatchPosition,
    GetPosition
}