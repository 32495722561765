import { GetDetailsFromStorage } from "../commonFunctions";
import API_CONST from "../constants/apiConstants";
import APP_CONST from "../constants/appConstants";
import HTTP_CONST from "../constants/httpConstants";
import { GetApiCall } from "./ApiCallRequestService";

function getServicesProviderByIdService(categoryId = "", ) {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve(require("../fake_services/VendorsByServiceId.json")[categoryId]);
      // }

      const params = {
        categoryId,
        customerId: (await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId
      }

      if(!params.customerId){
        return reject({code: HTTP_CONST.CODE.UNAUTHORISED, message :"User Not Authorised"})
      }

      let services = await GetApiCall(API_CONST.servicesByProviderId, params);

      return resolve(services);
    } catch (error) {
      return reject(error);
    }
  })
}

function getCatalogItemsProviderByIdService(serviceProviderId = "") {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve(require("../fake_services/AllCatalogsByProviderId.json")[serviceProviderId]);
      // }

      const params = {
        serviceProviderId
      }
      let items = await GetApiCall(API_CONST.itemsByProviderId, params);

      return resolve(items);
    } catch (error) {
      return reject(error);
    }
  })
}

function getServiceProviderByBusinessCodeService(businessCode) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(businessCode);
      let params = {};

      if(businessCode) params['businessCode'] = String(businessCode).padStart(10, "0").toUpperCase();
      let items = await GetApiCall(API_CONST.getServiceProviderByBusinessCode, params, APP_CONST.API_TYPES.MANAGE_SERVICE_PROVIDER_URL);

      return resolve(items);
    } catch (error) {
      console.log(error);
      return reject(error);
    }
  })
}

function isDeliveryAvailableForServiceProvider(serviceProviderId = "") {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve(require("../fake_services/AllCatalogsByProviderId.json")[serviceProviderId]);
      // }

      const params = {
        serviceProviderId
      }
      // setTimeout(() => {

      //   return resolve(true)
      // }, 1000)
      let isAvailable = await GetApiCall(API_CONST.deliveryAvailability, params);

      return resolve(isAvailable? !!isAvailable.deliveryService : true);
    } catch (error) {
      return reject(error);
    }
  })
}

export {
  getCatalogItemsProviderByIdService,
  getServicesProviderByIdService,
  isDeliveryAvailableForServiceProvider,
  getServiceProviderByBusinessCodeService
};