import { GetDetailsFromStorage } from "../commonFunctions";
import API_CONST from "../constants/apiConstants";
import APP_CONST from "../constants/appConstants";
import HTTP_CONST from "../constants/httpConstants";
import { GetApiCall, PostApiCall } from "./ApiCallRequestService";


function getAllServiceCataloguesService() {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve(require("../fake_services/AllServiceCatalogues.json"));
      // }

      let services = await GetApiCall(API_CONST.getAllServiceCatalogues);

      return resolve(services);
    } catch (error) {
      return reject(error);
    }
  })
}


function isCustomerMappedToServiceProvider(serviceProviderId) {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve({ status: true });
      // }

      const params = {
        serviceProviderId: Number(serviceProviderId),
        customerId: (await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId
      }

      if (!params.customerId) {
        return reject({ code: HTTP_CONST.CODE.UNAUTHORISED, message: "User Not Authorised" })
      }

      let services = await GetApiCall(API_CONST.customerServiceProviderMap, params, APP_CONST.API_TYPES.MANAGE_SERVICE_PROVIDER_URL);

      return resolve(services);
    } catch (error) {
      return reject(error);
    }
  })
}


function mapCustomerToServiceProvider(serviceProviderId) {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //     return resolve({status: true});
      // }

      const params = {
        serviceProviderId: Number(serviceProviderId),
        customerId: (await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId
      }

      if (!params.customerId) {
        return reject({ code: HTTP_CONST.CODE.UNAUTHORISED, message: "User Not Authorised" })
      }

      let services = await PostApiCall(API_CONST.customerServiceProviderMap, params, APP_CONST.API_TYPES.MANAGE_SERVICE_PROVIDER_URL);

      return resolve(services);
    } catch (error) {
      return reject(error);
    }
  })
}

export {
  getAllServiceCataloguesService,
  isCustomerMappedToServiceProvider,
  mapCustomerToServiceProvider
};