const API_CONST = {
  getAllServiceCatalogues: 'getCategories',
  servicesByProviderId: "getServiceProviders",
  deliveryAvailability: "deliveryAvailability",
  itemsByProviderId: "getItems",
  getServiceProviderByBusinessCode: "getServiceProviderByBusinessCode",
  addToCart: "addToCart",
  getCartSize: "getCartSize",
  getCartDetails: "getCartDetails",
  getCartDetailsComprehensive: "getCartDetailsComprehensive",
  deleteCart: "deleteCart",
  placeOrder: "order",
  getOrders: "getOrders",
  cancelOrder: "cancelOrder",
  customerServiceProviderMap: "customerServiceProviderMap",
  getOrderDetails: "getOrderDetails",
  getCustomerInfo: "getCustomerInfo",
  register: "addCustomer",
  sendOtp: "generateOtp",
  reSendOtp: "generateOtp",
  confirmOtp: "verifyOtp",
  checkCustomerExists: "checkCustomerExists",
}

export default API_CONST;