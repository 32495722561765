function ShippingImageComponent() {
    return (
        <svg width="25" height="28" viewBox="0 0 91 46" version="1.1" className="shipping-svg">

            <path name="wheel-back" d="M33.8 33.2C32 33.2 30.4 33.9 29.3 35 28.1 36.2 27.4 37.8 27.4 39.5 27.4 41.3 28.1 42.9 29.3 44.1 30.4 45.2 32 45.9 33.8 45.9 35.5 45.9 37.1 45.2 38.3 44.1 39.4 42.9 40.2 41.3 40.2 39.5 40.2 37.8 39.4 36.2 38.3 35 37.1 33.9 35.5 33.2 33.8 33.2L33.8 33.2ZM33.8 42.3C32.3 42.3 31 41 31 39.5 31 38 32.3 36.8 33.8 36.8 35.3 36.8 36.5 38 36.5 39.5 36.5 41 35.3 42.3 33.8 42.3L33.8 42.3Z" fill="currentColor" />

            <path name="cabin" d="M88.9 21.8L78.2 11C75.6 8.4 72.4 7 68.7 7L68 7 68 2.1 46 2.1C45.8 2.1 48 15.5 42 22 36.1 28.4 22 27.8 22 28 22 33.2 22 38.1 22 38.1L26.4 38.1C26.7 36.6 27.4 35.3 28.4 34.2 29.8 32.8 31.7 32 33.8 32 35.9 32 37.7 32.8 39.1 34.2 40.1 35.3 40.9 36.6 41.2 38.1L52.9 38.1 68 38.1 69.5 38.1C69.8 36.6 70.5 35.3 71.6 34.2 72.9 32.8 74.8 32 76.9 32 79 32 80.8 32.8 82.2 34.2 83.2 35.3 84 36.6 84.3 38.1L86.2 38.1C88.3 38.1 90 36.4 90 34.2L90 24.7C90 23.6 89.6 22.6 88.9 21.8ZM68.2 10.7L71.1 10.7C72.6 10.7 74 11.2 75.1 12.3L82.6 20 68.2 20 68.2 10.7Z" fill="currentColor" />

            <path name="wheel-front" d="M76.9 33.2C75.1 33.2 73.5 33.9 72.4 35 71.2 36.2 70.5 37.8 70.5 39.5 70.5 41.3 71.2 42.9 72.4 44.1 73.5 45.2 75.1 45.9 76.9 45.9 78.6 45.9 80.2 45.2 81.4 44.1 82.5 42.9 83.3 41.3 83.3 39.5 83.3 37.8 82.5 36.2 81.4 35 80.2 33.9 78.6 33.2 76.9 33.2L76.9 33.2ZM77 42.3C75.4 42.3 74.2 41 74.2 39.5 74.2 38 75.4 36.8 77 36.8 78.5 36.8 79.7 38 79.7 39.5 79.7 41 78.5 42.3 77 42.3L77 42.3Z" fill="currentColor" />

            <path name="smoke-3" d="M5 34C4.5 34 4 34.4 4 35 4 35.6 4.5 36 5 36L20 36C20.5 36 21 35.6 21 35 21 34.4 20.5 34 20 34L5 34Z" fill="currentColor" />

            <path name="smoke-2" d="M9 31C8.4 31 8 31.4 8 32 8 32.6 8.4 33 9 33L20 33C20.6 33 21 32.6 21 32 21 31.4 20.6 31 20 31L9 31Z" fill="currentColor" />

            <path name="smoke-1" d="M12 28C11.3 28 11 28.4 11 29 11 29.6 11.3 30 12 30L20 30C20.7 30 21 29.6 21 29 21 28.4 20.7 28 20 28L12 28Z" fill="currentColor" />

        </svg>
    )
}

export default ShippingImageComponent;
