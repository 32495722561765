import { ReactComponent as Logo } from '../../logo.svg';
import { Link } from "react-router-dom";
import './Menu.css';
import APP_CONST from "../../constants/appConstants";
import { Component } from "react";
import store from "../../redux/Store";
import { toggleMenuOpen } from "../../redux/actions/MenuAction";
import { REDUX_REDUCERS_VARIABLES } from "../../constants/reduxConstants";
// import { getAllServiceCataloguesService } from "../../services/ServiceCataloguesService";

let menus = [
  // {
  //   link: APP_CONST.routes.serviceCatalogues,
  //   title: APP_CONST.headers.serviceCatalogues,
  //   open: true,
  //   childrens: []
  // },
  {
    link: APP_CONST.routes.my_orders,
    title: APP_CONST.headers.my_orders,
    open: false,
    childrens: [{
      link: APP_CONST.routes.my_orders + "?date=" + APP_CONST.dates[0],
      title: APP_CONST.dates[0],
      icon: "gg-calendar-today"
    }, {
      link: APP_CONST.routes.my_orders + "?date=" + APP_CONST.dates[2].replace(/ /g, "+"),
      title: APP_CONST.dates[2],
      icon: "gg-calendar"
    }]
  },
  {
    link: APP_CONST.routes.add_service_provider,
    title: "Add New Service",
    open: true,
    childrens: [
      {
        link: APP_CONST.routes.add_service_provider,
        title: APP_CONST.headers.add_service_provider,
        icon: "gg-scan"
      }, 
      {
        link: APP_CONST.routes.add_service_provider_using_business_id,
        title: "Use Business Code",
        icon: "gg-font-height"
      }
    ]
  }
]
class MenuComponent extends Component {

  state = {
    IS_MENU_OPEN: false,
    IS_USER_LOGGED_IN: false,
    MENUS_TO_SHOW: []
  }

  subscribe = null;

  async componentDidMount() {

    // try {
    //   const serviceCatalogues = await getAllServiceCataloguesService();
    //   if (serviceCatalogues && serviceCatalogues.length) {
    //     menus[0].childrens = serviceCatalogues.map((serviceCatalogue) => {
    //       return {
    //         link: APP_CONST.routes.service_providers.replace(":category_id", serviceCatalogue.categoryId),
    //         title: serviceCatalogue.categoryName,
    //         image: serviceCatalogue.categoryIconURL
    //       }
    //     });
    //   }
    // } catch (error) {

    // }

    this.setMenusToShow();

    this.subscribe = store.subscribe(() => {
      const _state = store.getState();
      this.setState({
        IS_USER_LOGGED_IN: _state[REDUX_REDUCERS_VARIABLES.IS_USER_LOGGED_IN],
        IS_MENU_OPEN: _state[REDUX_REDUCERS_VARIABLES.IS_MENU_OPEN]
      }, () => this.setMenusToShow());
    })
  }

  setMenusToShow() {
    const _menus = menus.filter(menu => {
      if (menu.open)
        return menu;
      else if (this.state.IS_USER_LOGGED_IN)
        return menu;
      return false;
    })
    // console.log(_menus);
    this.setState({ MENUS_TO_SHOW: _menus })
  }

  closeMenu() {
    toggleMenuOpen();
  }


  render() {
    const { MENUS_TO_SHOW } = this.state;
    return (
      <div className="menu-container" style={{ display: this.state.IS_MENU_OPEN ? "block" : "none" }} onClick={() => this.closeMenu()}>
        <nav>
          <Link to={APP_CONST.routes.serviceCatalogues}>
            <div className="background-primary p-10 align-center">
              <Logo />
              {/* <p className="m-0 p-10 align-center color-white">Shopwala </p> */}
            </div>
          </Link>
          {
            MENUS_TO_SHOW.map((menu, i) => {
              return (
                <div key={i}>
                  <Link to={menu.link} key={"link_" + i}>
                    <div key={"div_" + i} className="main-menu">
                      <p className="m-0 color-primary" key={"link_" + i}>{menu.icon && <i className={menu.icon}></i>} {menu.title}</p>
                    </div>
                  </Link>
                  {
                    menu.childrens.map((submenu, si) => {
                      return (
                        <Link to={submenu.link} key={"link_s_" + si}>
                          <div key={"div_s_" + si} className="sub-menu">
                            <div className="m-0 color-primary flex" key={"link_s_" + si}>{submenu.icon && !submenu.image && <i className={submenu.icon} style={{ marginRight: "10px" }}></i>} {submenu.image && <img className='menu-img' src={submenu.image} alt={submenu.title} />} {submenu.title}</div>
                          </div>
                        </Link>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </nav>
      </div>
    );
  }

  componentWillUnmount() {
    if (this.subscribe)
      this.subscribe();
  }
}

export default MenuComponent;
