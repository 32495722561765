import { Component } from "react";
import HeaderComponent from "../../common_components/header/Header";
import CalendarComponent from "../../common_components/calendar/Calendar";


import APP_CONST from "../../constants/appConstants";
import { getMyOrdersService } from "../../services/OrderService";
import { Link } from "react-router-dom";

import './MyOrders.css';
import { showLoader } from "../../redux/actions/LoaderAction";
import { ConvertUrlQueryToObject, DateFormat, SplitCamelCaseString } from "../../commonFunctions";
import { showSnackBar } from "../../redux/actions/SnackBarAction";
import EmptyImageComponent from "../../common_components/empty-image/EmptyImage";
import ShippingImageComponent from "../../common_components/shippingImage/ShippingImage";

class MyOrdersComponent extends Component {


  state = {
    my_orders: [],
    actual_orders: [],
    selected_status: "All",
    selected_type: 'All',
    dates: [],
    selected_date: "Today",
    showCalendar: false,
    filters: { status: "All", type: "All", date: "Today" },
    showFilter: false,
    noOrdersFlag: false
  }

  componentDidMount() {
    this.getOrders();
  }

  async getOrders() {
    showLoader(true);
    try {
      let filters = ConvertUrlQueryToObject(this.props.location.search);
      if (filters.date === 'Custom' && (!filters.start || !filters.end)) {
        this.props.history.goBack();
        return;
      }

      const { start, end } = JSON.parse(JSON.stringify(filters));

      delete filters.start;
      delete filters.end;

      if (!Object.keys(filters).length) {
        filters = this.state.filters;
      }

      this.setState({
        filters,
        selected_date: filters.date || this.state.selected_date,
        selected_type: filters.type || this.state.selected_type,
        selected_status: filters.status || this.state.selected_status,
        dates: start && end ? [new Date(start), new Date(end)] : this.state.dates
      });

      let params = { dateRange: 1 };

      if (filters.date === "Last two days") params.dateRange = 2;
      if (filters.date === "Last three days") params.dateRange = 3;
      if (filters.date === "Custom") params.dateRange = `custom~${DateFormat(start, 'yyyy-mm-dd')}:${DateFormat(end, 'yyyy-mm-dd')}`;

      let _my_orders = await getMyOrdersService(params);
      showLoader(false);

      if (Array.isArray(_my_orders) && _my_orders.length) {

        this.setState({
          my_orders: _my_orders,
          actual_orders: _my_orders
        }, () => this.getFilteredOrder());

      } else {
        this.setState({ noOrdersFlag: true });
      }

    } catch (error) {
      showLoader(false);
      showSnackBar(error.message)
    }

  }

  filterByStatus(selected_status = '') {
    let { filters } = this.state;
    filters.status = selected_status;
    this.setState({ selected_status, filters }, () => this.addQueryToUrl());
  }

  filterByType(selected_type = '') {
    let { filters } = this.state;
    filters.type = selected_type;

    this.setState({ selected_type, filters }, () => this.addQueryToUrl());
  }

  changeStatus(str = "", order) {
    if (APP_CONST.orderRejected === str) return `Cancelled By ${order && order.serviceProviderName ? order.serviceProviderName : "Supplier"}`;
    if (APP_CONST.orderCancelled === str) return `Cancelled By Me`;
    return str;
  }

  async filterByDate(selected_date) {
    let { filters } = this.state;

    filters.date = selected_date;
    this.setState({ selected_date, filters }, () => this.addQueryToUrl());
    if (selected_date === 'Custom') { this.setState({ showCalendar: true }); return };

    let dateRange = selected_date === "Today" ? 1 : (selected_date === "Last two days" ? 2 : 3);

    let _my_orders = await getMyOrdersService({ dateRange });

    this.setState({
      my_orders: _my_orders,
      actual_orders: _my_orders
    }, () => this.getFilteredOrder());

  }

  addQueryToUrl(dates = {}) {
    let { filters } = this.state;
    if (filters.date === 'Custom' && !dates.end) {
      dates = { start: this.state.dates[0], end: this.state.dates[this.state.dates.length - 1] }
    }
    filters = { ...filters, ...dates };
    this.props.history.push(this.props.location.pathname + "?" + new URLSearchParams(filters).toString())
  }

  filterChanged() {
    let { selected_type, selected_status, selected_date, dates } = this.state;

    this.getFilteredOrder({ selected_type, selected_status, selected_date, dates });
  }

  getFilteredOrder({ selected_type, selected_status, selected_date, dates } = this.state) {
    showLoader(true);

    this.setState({ noOrdersFlag: false });

    let date = new Date(), endDate;

    if (selected_date === 'Today') {
      date = DateFormat(date, "")
    } else if (selected_date === 'Last two days') {
      date = DateFormat(date.setDate(date.getDate() - 1), "")
    } else if (selected_date === 'Last three days') {
      date = DateFormat(date.setDate(date.getDate() - 2), "")
    } else if (selected_date === 'Custom') {
      date = dates[0];
      endDate = dates[dates.length - 1];
      endDate.setHours(0, 0, 0, 0)
    }
    date.setHours(0, 0, 0, 0);

    let orders = this.state.actual_orders.filter((order) => {
      let orderDate = DateFormat(order.orderDate, "");
      orderDate.setHours(0, 0, 0, 0);
      if (selected_date !== 'Custom') {
        return orderDate >= date
      } else {
        return orderDate >= date && orderDate <= endDate
      }
    });

    if (selected_status === 'All' && selected_type === 'All') {
      this.setState({ my_orders: orders });
    } else {
      let status = selected_status === 'All' ? APP_CONST.orderStatus : [selected_status];
      let type = selected_type === 'All' ? [true, false] : [selected_type === APP_CONST.orderTypeDelivery];
      orders = orders.filter(order => {
        return status.includes(order.orderStatus) && type.includes(order.isDeliver);
      });

      this.setState({ my_orders: orders })
    }

    if (!orders || !orders.length) {
      this.setState({ noOrdersFlag: true });
    }
    showLoader(false);

  }

  async onDateSelected(dates) {
    if (dates.length > 90) {
      showSnackBar("Selected date should be under 90 days!");
      return;
    }
    this.setState({ showCalendar: false, dates });
    this.addQueryToUrl({ start: dates[0], end: dates[dates.length - 1] });

    let _my_orders = await getMyOrdersService({ dateRange: `custom~${DateFormat(dates[0], "yyyy-mm-dd")}:${DateFormat(dates[dates.length - 1], "yyyy-mm-dd")}` });

    this.setState({
      my_orders: _my_orders,
      actual_orders: _my_orders
    });

    const { selected_type, selected_status, selected_date } = this.state;
    this.getFilteredOrder({ selected_type, selected_status, selected_date, dates });
  }

  toggleFilter() {
    const { showFilter } = this.state;

    this.setState({ showFilter: !showFilter }, () => { if (!this.state.showFilter) this.filterChanged() })
  }

  statusClicked(filter, value) {
    if (filter === 'status') {
      this.filterByStatus("All");
    } else if (filter === 'type') {
      this.filterByType("All");
    } else if (filter === 'date' && value !== 'Today') {
      this.filterByDate("Today");
    }

    const { showFilter } = this.state;
    this.setState({ showFilter: !!showFilter }, () => { if (!this.state.showFilter) this.filterChanged() })
  }


  render() {
    const { my_orders, showCalendar, filters, showFilter, noOrdersFlag } = this.state;
    return (
      <>
        <HeaderComponent title={APP_CONST.headers.my_orders} showBack={true}></HeaderComponent>

        <div className="content">
          <div className="filters">
            <div className="filter-svg" onClick={() => this.toggleFilter()}>
              <svg version="1.1" id="Layer_1" x="0px" y="0px"
                viewBox="0 0 550 550" fill="#082e53">
                <g>
                  <g>
                    <polygon points="472.615,12.908 0,12.908 180.081,202.629 180.066,459.708 292.55,401.525 292.534,202.629" fill="#082e53" />
                  </g>
                </g>
              </svg>
            </div>
            {
              Object.keys(filters).map(filter => {
                return (filters[filter] !== 'All' ? <span className="chip p-10 m-10 selected" key={filter} onClick={() => this.statusClicked(filter, filters[filter])}>{filter.toLocaleUpperCase() + ": " + SplitCamelCaseString(filters[filter])} {filters[filter] !== "Today" && <span>X</span>}</span> : <i key={filter}></i>)
              })
            }
          </div>

          {
            noOrdersFlag && <div className="center-screen align-center font-bold">
              <EmptyImageComponent></EmptyImageComponent>
              <p>No orders for selected filter.</p>
              <p>Change the combination in filter and try again!!</p>
            </div>
          }
          <div className="grid">
            {
              my_orders.map((order, i) => {
                return (
                  <Link to={APP_CONST.routes.order_details.replace(":order_id", order.orderId)} key={i} className='card m-b-0'>
                    <div className="flex main m-0 w-100" key={i}>
                      <p className={(!order.isDeliver ? "p-l-10 " : " ") + order.orderStatus}>{order.isDeliver ? <ShippingImageComponent></ShippingImageComponent> : <i className="gg-shopping-bag"></i>}</p>
                      <div className="w-100 m-lr-10 p-l-10">
                        <div className="m-0"><b>{order.subCategoryName ? order.subCategoryName + " - " : ""}{order.serviceProviderName}</b></div>
                        <div className="m-0 m-t-5 font-bold font-80 address-fontsize">Order #{order.orderId}</div>
                        <div className="m-0 m-t-5 font-bold font-80 address-fontsize">{DateFormat(order.orderDate, `${APP_CONST.shortdateFormat}, ${APP_CONST.timeFormat}`)}</div>
                        <div className={"m-0 m-t-5 font-bold font-80 address-fontsize " + order.orderStatus}>{SplitCamelCaseString(this.changeStatus(order.orderStatus, order))}</div>
                      </div>
                      <p className="flex font-bold p-r-10 p-l-10">{order.totalPrice?<><i className="inr-sign"></i>&nbsp;<span>{order.totalPrice}</span></>:""}</p>
                    </div>
                  </Link>
                )
              })
            }
          </div>
        </div>
        <div className="fab-icon cursor-pointer color-white more-undo" onClick={() => { this.getOrders() }}>
          <span className="undo"><i className="gg-undo"></i></span>
        </div>
        {
          <div className={"content filters-popup" + (showFilter ? " show" : "")}>
            <div className="flex m-0 cursor-pointer background-primary filter-heading p-lr-10 color-white">
              <i className="gg-arrow-left" onClick={() => { this.toggleFilter(); }}></i>
              <span className="p-10">Filters</span>
            </div>
            <div className="grid filter-content m-b-20">
              <div className="card m-b-0">
                <label className="font-bold">Date:</label>
                {
                  APP_CONST.dates.map((date) => {
                    return (<div className="p-10 p-b-0 font-80 color-light flex" key={date}><input type="radio" name="date" checked={filters.date === date} id={"date_" + date} onChange={e => this.filterByDate(date)} /><label className="p-l-10" htmlFor={"date_" + date}>{date}</label></div>)
                  })
                }
              </div>
              <div className="card m-b-0">
                <label className="font-bold">Type:</label>
                {
                  APP_CONST.orderType.map((type) => {
                    return (<div className="p-10 p-b-0 font-80 color-light flex" key={type}><input type="radio" name="type" checked={filters.type === type} id={"type_" + type} onChange={e => this.filterByType(type)} /><label className="p-l-10" htmlFor={"type_" + type}>{type}</label></div>)
                  })
                }
              </div>
              <div className="card m-b-0">
                <label className="font-bold">Status:</label>
                {
                  APP_CONST.orderStatus.map((status) => {
                    return (<div className="p-10 p-b-0 font-80 color-light flex" key={status}><input type="radio" name="status" checked={filters.status === status} id={"status_" + status} onChange={e => this.filterByStatus(status)} /><label className="p-l-10" htmlFor={"status_" + status}>{SplitCamelCaseString(this.changeStatus(status, ""))} </label></div>)
                  })
                }
              </div>
            </div>
            <CalendarComponent range={true} show={showCalendar} onDateSelected={(dates) => this.onDateSelected(dates)}></CalendarComponent>
          </div>
        }
      </>
    );
  }
}
export default MyOrdersComponent;