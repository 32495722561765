import {
  GetDetailsFromStorage,
} from "../commonFunctions";
import API_CONST from "../constants/apiConstants";
import APP_CONST from "../constants/appConstants";
// import { REDUX_REDUCERS_VARIABLES } from "../constants/reduxConstants";
import { deleteCartAction } from "../redux/actions/CartAction";
// import store from "../redux/Store";
import { GetApiCall, PostApiCall } from "./ApiCallRequestService";


function addToCartService(data) {
  return new Promise(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === "development") {
      //   return resolve({});
      // }
      data = JSON.parse(JSON.stringify(data));
      
      delete data.itemName;
      delete data.price;
      delete data.unitOfMeasure;
      delete data.subCategoryName;
      data.customerId = (await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId;
      let addToCart = await PostApiCall(API_CONST.addToCart, data);

      return resolve(addToCart);

    } catch (error) {
      return reject(error);
    }
  });
}

function getCartDetailsService(serviceProviderId) {
  return new Promise(async (resolve, reject) => {
    try {

      // if (process.env.NODE_ENV === "development") {
      //   let cartDetails = store.getState()[REDUX_REDUCERS_VARIABLES.CART_DETAILS];
      //   return resolve(cartDetails);
      // }
      
      const params = {
        serviceProviderId,
        customerId:(await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId
      }
 
      let cartDetails = await GetApiCall(API_CONST.getCartDetails, params);

      return resolve(cartDetails);

    } catch (error) {
      return reject(error);
    }
  });
}

function getCartDetailsComprehensive(serviceProviderId) {
  return new Promise(async (resolve, reject) => {
    try {

      // if (process.env.NODE_ENV === "development") {
      //   let cartDetails = store.getState()[REDUX_REDUCERS_VARIABLES.CART_DETAILS];
      //   return resolve(cartDetails);
      // }
      
      const params = {
        serviceProviderId,
        customerId:(await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId
      }
 
      let cartDetails = await GetApiCall(API_CONST.getCartDetailsComprehensive, params);

      return resolve(cartDetails);

    } catch (error) {
      return reject(error);
    }
  });
}

function deleteCartService(serviceProviderId) {
  return new Promise(async (resolve, reject) => {
    try {


      // if (process.env.NODE_ENV === "development") {
      //   deleteCartAction(serviceProviderId);
      //   return resolve([]);
      // }

      const body = {
        serviceProviderId,
        customerId:(await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId
      }
      
      let cartRemoved = await PostApiCall(API_CONST.deleteCart, body);
      deleteCartAction(serviceProviderId);

      return resolve(cartRemoved);

    } catch (error) {
      return reject(error);
    }
  })
}

function checkCartService(service_provider_id) {
  return new Promise(async (resolve, reject) => {
    try {
      const cart = await getCartDetailsService(service_provider_id);
      return resolve(cart && cart.cartId);

    } catch (error) {
      return reject(error);
    }
  })
}

function getCartSizeService(serviceProviderId) {
  return new Promise(async (resolve, reject) => {
    try {



      
      // if (process.env.NODE_ENV === "development") {
      //   const _cartSize = store.getState()[REDUX_REDUCERS_VARIABLES.CART_COUNT];
      //   return resolve(_cartSize);
      // }

      const params = {
        serviceProviderId,
        customerId:(await GetDetailsFromStorage(APP_CONST.user_details_key)).customerId
      }
      

      let CartSize = await GetApiCall(API_CONST.getCartSize, params);
      return resolve(CartSize && CartSize.count ? Number(CartSize.count): 0);
            
    } catch (error) {
      return reject(error);
    }
  })
}

export {
  addToCartService,
  getCartDetailsService,
  getCartDetailsComprehensive,
  getCartSizeService,
  deleteCartService,
  checkCartService,
};