import './ShowNotSubscribed.css';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { RemoveScript } from '../../commonFunctions';

class ShowNotSubscribedComponent extends Component {

  // constructor(props) {
  //   super(props);
  // }

  componentDidUpdate(prevProps) {
    // console.log(prevProps);
  }

  render() {

    const { showNotSubscribed, message, videoUrl } = this.props;

    return (
      <div>
        {
          showNotSubscribed &&
          <div className="overlay-popup">
            <div className="card not-subcribed">
              <i className="gg-close" style={{ marginLeft: "auto" }} onClick={() => this.props.close()}></i>
              <div className="m-10 welcome-content" dangerouslySetInnerHTML={{ __html: decodeURIComponent(RemoveScript(message)) }} ></div>
              {
                videoUrl &&
                <iframe className="m-10" src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }
            </div>
          </div>
        }
      </div>
    );
  }

  componentWillUnmount() {
    if (this.subscribe)
      this.subscribe();
    window.removeEventListener("click", this.outsideClicked, false);
  }
}

export default withRouter(ShowNotSubscribedComponent);

