export const REDUX_ACTION = {
  DELETE_CART: "deleteCart",
  CHANGE_CART_RESETED: "changeCartReseted",
  UPDATE_CART_COUNT: "updateCartCount",
  TOGGLE_USER_CONFIRMATION_ON_CART: "toggleUserConfirmationOnCart",
  TOGGLE_MENU_OPEN: "toggleMenuOpen",
  IS_USER_LOGGED_IN: "isUserLoggedInAction",
  SHOW_MESSAGE: "showMessage",
  SHOW_LOADER: "showLoader",
  SHOW_SNACKBAR: "showSnackBar",
  RESET_STATE: "resetState"
};

export const REDUX_REDUCERS_VARIABLES = {
  CHANGE_CART_RESETED: "CHANGE_CART_RESETED",
  CART_COUNT: "CART_COUNT",
  IS_MENU_OPEN: "IS_MENU_OPEN",
  IS_USER_CONFIRMED_TO_CONTINUE_CART: "IS_USER_CONFIRMED_TO_CONTINUE_CART",
  SHOW_LOADER: "SHOW_LOADER",
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  IS_USER_LOGGED_IN: "IS_USER_LOGGED_IN",
  SHOW_MESSAGE: "SHOW_MESSAGE",
  RESET_STATE: "RESET_STATE",
  CART_RESETED: "CART_RESETED"
}

export const REDUX_REDUCERS_INITIAL_STATE = {
  CART_COUNT: 0,
  IS_MENU_OPEN: false,
  IS_USER_CONFIRMED_TO_CONTINUE_CART: false,
  SHOW_LOADER: false,
  IS_USER_LOGGED_IN: false,
  CART_RESETED: false,
  SHOW_MESSAGE: false,
  SHOW_SNACKBAR: "",
}
