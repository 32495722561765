import { Component } from "react";
import HeaderComponent from "../../common_components/header/Header";
import { addToCartService, getCartDetailsComprehensive } from "../../services/CartService";
import './Cart.css';
import { placeOrderService } from "../../services/OrderService";
import APP_CONST from "../../constants/appConstants";
import { toggleUserConfirmationOnCart } from "../../redux/actions/CartAction";
import { showLoader } from "../../redux/actions/LoaderAction";
import { showSnackBar } from "../../redux/actions/SnackBarAction";
import LazyImageLoadComponent from "../../common_components/lazyImageLoad/LazyImageLoad";
import EmptyImageComponent from "../../common_components/empty-image/EmptyImage";
import { isDeliveryAvailableForServiceProvider , getServiceProviderByBusinessCodeService} from "../../services/ServiceProvidersService";
import HTTP_CONST from "../../constants/httpConstants";


class CartComponent extends Component {

	state = {
		items: [],
		isVisible: false,
		isDeliver: false,
		flag: false,
		isDeliverAvailableCheckIsInProgress: true,
		isDeliverAvailableForServiceProvider: true,
		serviceProvider: {
			serviceProviderName: ""
		},
		serviceProviderId: 0
	}

	async componentDidMount() {

		toggleUserConfirmationOnCart(true);
		showLoader(true);
		try {
			const service_provider_id = await getServiceProviderByBusinessCodeService(this.props.match.params.service_provider_id);

			console.log({service_provider_id});
			this.setState({
				serviceProviderId: service_provider_id.serviceProviderId
			})
			let _carts = await getCartDetailsComprehensive(this.state.serviceProviderId);

			_carts.serviceProviderDetails && this.setState({ serviceProvider: _carts.serviceProviderDetails });

			if (_carts && _carts.carts.length) {
				this.setState({ items: _carts.carts });
			} else {
				this.props.history.goBack();
			}
		} catch (error) {

		}
		showLoader(false);

	}

	emptyCart() {
		if (this.state.items && this.state.items.length === 0) {
			this.setState({
				flag: true
			})
		}
	}

	async order() {
		if (this.state.isDeliverAvailableCheckIsInProgress) return;

		showLoader(true);
		const orderPlaced = await placeOrderService({ serviceProviderId: this.state.serviceProviderId, isDeliver: this.state.isDeliver && this.state.isDeliverAvailableForServiceProvider });

		if (orderPlaced && orderPlaced.orderId) {
			showSnackBar("Order Placed");
			this.props.history.push(APP_CONST.routes.order_details.replace(":order_id", orderPlaced.orderId))
		}
		showLoader(false);

	}

	async chooseDeliveryType(type) {
		this.setState({
			isDeliver: type === 'D' && this.state.isDeliverAvailableForServiceProvider
		});
	}

	async removeOverlay() {
		this.setState({ isVisible: false });
	}

	async addToCart(item) {
		this.setState({
			item: item.quantity++
		})
		await this.updateCart(item);
	}

	async removeFromCart(item, i) {
		if (item.quantity > 0) this.setState({
			item: item.quantity--
		});
		else {
			let { items } = this.state;

			items.splice(i, 1)

			this.setState({ items });
		}
		await this.updateCart(item);
	}

	async updateCart(item) {
		if (this.timeout) clearTimeout(this.timeout);
		let _cartObj = {
			...item,
			serviceProviderId: Number(this.state.serviceProviderId)
		};
		this.timeout = setTimeout(async () => {
			await addToCartService(_cartObj);
			const qty = this.state.items.reduce((p, item) => p + item.quantity, 0);
			if (!qty) this.props.history.goBack()
		}, 300);
	}

	async checkServiceProviderIsDeliverOption() {
		try {

			const _deliverOption = await isDeliveryAvailableForServiceProvider(this.state.serviceProviderId);
			this.setState({
				isDeliverAvailableForServiceProvider: _deliverOption,
				isDeliverAvailableCheckIsInProgress: false,
				isDeliver: this.state.isDeliver && _deliverOption
			});
		} catch (error) {
		}
	}

	render() {
		const { items, isVisible, isDeliver, isDeliverAvailableForServiceProvider, isDeliverAvailableCheckIsInProgress, serviceProvider } = this.state;

		let total = 0, qty = 0;
		return (
			<>
				{
					isVisible &&
					<div className="overlay-popup" >
						<div className="card align-center" >
							<p className="font-bold m-b-10">Choose delivery type</p>
							<div className={"flex m-tb-20 m-t-10" + (!isDeliverAvailableForServiceProvider ? " m-b-5" : "")} style={{ justifyContent: "center" }}>
								<span className={"color-white chip chip-cart" + (isDeliver ? " selected border-1" : "") + (!isDeliverAvailableForServiceProvider ? " disabled" : "")} onClick={() => this.chooseDeliveryType("D")}>Delivery</span>
								<span className={"color-white chip chip-cart" + (!isDeliver ? " selected border-1" : "")} onClick={() => this.chooseDeliveryType("P")}>Pickup</span>
							</div>
							{
								!isDeliverAvailableForServiceProvider && <span className="font-80" style={{ color: '#ff0033' }}>{HTTP_CONST.MESSAGE.DELIVERY_NOT_AVAILABLE}</span>
							}
							<div className="flex m-t-10 p-10" style={{ justifyContent: "center" }}>
								<button className="button-border m-lr-10" onClick={() => this.removeOverlay()}>Cancel</button>
								<button className="button-filled m-lr-10" disabled={isDeliverAvailableCheckIsInProgress} onClick={() => this.order()}>Confirm {isDeliverAvailableCheckIsInProgress && <i className="gg-spinner"></i>}</button>
							</div>
						</div>

					</div>
				}

				<HeaderComponent title={APP_CONST.headers.cart.replace("<SHOP_NAME>", (serviceProvider && serviceProvider.serviceProviderName) || "")}></HeaderComponent>
				<div className="content m-b-50">
					<div className="grid">
						{
							items.map((item, i) => {
								return (item.quantity ?
									<div className="card m-b-0" key={i}>

										<div className="flex main m-0 w-100">
											<LazyImageLoadComponent class="item-img" allowfullscreen='true' src={item.itemIconURL} targetWindow="false"></LazyImageLoadComponent>
											<div className="p-10 w-100">
												<div className="m-0"><b>{item.itemName}</b></div>
												<div className="color-light m-t-5">{item.subCategoryName}</div>
												<div className="flex font-80 color-light m-t-5 justify-content-start">
													{item.price ? <><i className="inr-sign"></i>&nbsp;
														{item.price} </> : ""}
												</div>
												{
													Array.isArray(item.subItems) &&
													<p>{item.subItems.reduce((p, c, i) => {
														console.log(i);
														p += (!i ? c.itemName : (i !== item.subItems.length - 1 ? ", " : " and ") + c.itemName) + (c.quantity ? "(" + c.quantity + ")" : "");
														return p;
													}, "")}</p>
												}
											</div>
											<div className="flex" style={{ alignItems: "flex-end" }}>
												<div className="flex">
													<i className="gg-math-minus"
														onClick={(e) => { e.stopPropagation(); this.removeFromCart(item, i) }}></i>
													<input className="quantity" type="number" key={'input_' + item.itemId} disabled value={item.quantity} />
													<i className="gg-math-plus" onClick={(e) => { e.stopPropagation(); this.addToCart(item) }}></i>
												</div>
											</div>
											<span style={{ display: 'none' }}>{total += (item.price * item.quantity)}{qty += item.quantity}</span>
										</div>
									</div>
									: <span key={i}></span>)
							})
						}
					</div>


					{
						qty === 0 && <div className="center-screen align-center font-bold">
							<EmptyImageComponent></EmptyImageComponent>
							<p>Your cart is empty for {(serviceProvider && serviceProvider.serviceProviderName) || this.props.match.params.name}</p>
							<p>Redirecting to Items page...</p>
						</div>
					}
				</div>
				{qty !== 0 &&
					<div className="footer flex main w-100 m-0 p-0 background-white">
						<p className="p-lr-10 color-light" style={{ marginLeft: "10px" }}>{total ? <>Total: <i className="inr-sign"></i>&nbsp;{total}</> : ""}</p>
						<button className="button-filled" disabled={!qty} style={{ marginRight: "15px" }} onClick={(e) => { this.checkServiceProviderIsDeliverOption(); this.setState({ isVisible: true }) }}>Order</button>
					</div>
				}
			</>
		);
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout)
		}
	}
}
export default CartComponent;