import './SnackBar.css';
import { Component } from 'react';
import store from '../../redux/Store';
import { REDUX_REDUCERS_VARIABLES } from '../../constants/reduxConstants';
import { showSnackBar } from '../../redux/actions/SnackBarAction';
import { RemoveScript } from '../../commonFunctions';

class SnackBarComponent extends Component {

  subscribe = null;

  state = {
    snackMessage: "",
  }


  subscribe;

  timeout;

  async componentDidMount() {
    this.subscribe = store.subscribe(() => {
      this.setMessage(store.getState()[REDUX_REDUCERS_VARIABLES.SHOW_SNACKBAR]);

    });

  }

  setMessage(snackMessage = "", closeAutomatically = true) {
    this.setState({ snackMessage });
    if (snackMessage && closeAutomatically) {
      this.timeout = setTimeout(() => {
        showSnackBar("");
      }, 4000)
    }
  }

  dismiss() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    showSnackBar("");
  }

  render() {
    const { snackMessage } = this.state;
    return (
      <> {
        snackMessage &&
        <div className="snack-bar" style={{ display: snackMessage ? "block" : "none" }} >
          <div className="flex">
            <p className="m-0" dangerouslySetInnerHTML={{ __html: RemoveScript(snackMessage) }} ></p>
            <button className="cursor-pointer" onClick={() => this.dismiss()}>Dismiss</button></div>
        </div>
      }
      </>
    );
  }

  componentWillUnmount() {
    if (this.subscribe)
      this.subscribe();

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}

export default SnackBarComponent;

