const APP_CONST = {
  token_key: "__lsw__t__",
  store_key: "__lsw__store__",
  user_details_key: "__lsw__u__",
  app_name: "Local Shopwala",
  app_host: window.location.host,
  not_available_message: (which_item = 'Data') => `${which_item} not available`,
  service_provider: {
    not_available: (which_category = 'Data') => `<p>You are not subscribed to ${which_category.bold()} services.</p><p>Please reach out to your local ${which_category.bold()} center to invite you</p>`
  },
  routes: {
    serviceCatalogues: "/",
    register: "/register",
    add_service_provider: "/add_service_provider",
    add_service_provider_using_business_id: "/add_service_provider_using_business_id",
    service_providers: "/service_providers/:category_id",
    catelog_items: "/catelog_items/:service_provider_id",
    cart: "/cart/:service_provider_id",
    my_orders: "/my_orders",
    order_details: "/order_detail/:order_id",
    login: "/login",
  },
  headers: {
    serviceCatalogues: "Service Catalogue",
    add_service_provider: "Scan QR code",
    add_service_provider_using_business_id: "Enter Business Id",
    register: "Register",
    cart: "Cart for <SHOP_NAME>",
    my_orders: "My Orders",
    order_details: "Order No. - <ORDER_NUMBER>"
  },
  shortdateFormat: "dd MMM",
  dateFormat: "sday, dd MMM, yyyy",
  timeFormat: "H:MM:SS",
  orderPending: "Pending",
  orderAccepted: "Accepted",
  orderRejected: "Rejected",
  orderCancelled: "Cancelled",
  orderCompleted: "Completed",
  orderStatus: ["All", "Pending", "Accepted", "Cancelled", "Rejected", "OutToDeliver", "ReadyToPick", "Completed"],
  orderTypeDelivery: "Delivery",
  orderTypePickup: "Pickup",
  orderType: ["All", "Delivery", "Pickup"],
  dates: [
    "Today",
    "Last two days",
    "Last three days",
    "Custom"
  ],
  images: {
    1: "https://image.flaticon.com/icons/png/512/1261/1261052.png",
    2: "https://img.icons8.com/ios/452/herbal-medicine.png",
    3: "https://image.flaticon.com/icons/png/512/1487/1487890.png",
    4: "https://static.thenounproject.com/png/80050-200.png",
    5: "https://cdn-icons-png.flaticon.com/512/701/701965.png"
  },
  API_TYPES: {
    CUSTOMER_URL: "CUSTOMER_URL",
    MANAGE_CUSTOMER_URL: "MANAGE_CUSTOMER_URL",
    MANAGE_SERVICE_PROVIDER_URL: "MANAGE_SERVICE_PROVIDER_URL"
  },
  MESSAGE: {
    NOT_SUBSCRIBED: (category = "") => `<p>Dear Customer,</p>\n\n<p><b>${APP_CONST.app_name}</b> welcomes you! We provide a wide range of services ${category ? 'in <b>' + category + '</b> along with other services' : ''}.</p>\n\n<p>Since you are not subscribed, visit your nearest ${category} center and request to invite you or scan the QR code.</p>\n\n<p>We are sure you will love our services.</p>\n\n<p>Thank You</p>`,
    WELCOME_MESSAGE_WITHOUT_SERVICE_PROVIDER: (userName = "") => `<p>Dear ${userName},</p>\n\n<p><b>${APP_CONST.app_name}</b> welcomes you! We provide a wide range of services.</p>\n\n<p>Since you are not subscribed to any of our service, visit your nearest center and request to invite you or scan the QR code.</p>\n\n<p>We are sure you will love our services.</p>\n\n<p>Thank You</p>`,
    WELCOME_MESSAGE_WITH_SERVICE_PROVIDER: (userName = "", serviceProvider = "") => `<p>Dear ${userName},</p>\n\n<p><b>${APP_CONST.app_name}</b> welcomes you! We provide a wide range of services.</p>\n\n<p>You are now subscribed to <b>${serviceProvider}</b>, we are sure you will love our services.</p>\n\n<p>Thank You</p>`,
  }
}

export default APP_CONST;